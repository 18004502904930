import * as React from 'react';

import {
    FunctionField,
    Edit,
    SimpleForm,
    useTranslate,
    TextField,
    List as RaList,
    NumberField,
    BooleanField,
    DeleteWithConfirmButton,
    useRefresh,
    FormDataConsumer,
    Datagrid,
    UrlField,
    useDataProvider,
    DateField,
    Show,
    TranslatableFields,
    ArrayField,
    SimpleShowLayout,
    WithListContext, usePermissions
} from 'react-admin';
import {
    Box,
    Typography,
    Card,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    useMediaQuery,
    Theme,
    Stack,
    Chip,
    ListItemButton,
    ListItemAvatar,
    Avatar, Grid
} from '@mui/material';

import {formatPrice} from '../formatUtils';

import Aside from './Aside';
import {Numbers, Percent, QrCode2, Straighten, ColorLens} from "@mui/icons-material";
import {AccordionSection} from "@react-admin/ra-form-layout";
import {
    Product,
    ProductMarketplaceAllocation,
    ProductTag
} from "../types";
import MarketplaceAllocationCreateForm from "./MarketplaceAllocationCreateForm";
import DefaultMarketplaceAllocationUpdateForm from "./DefaultMarketplaceAllocationUpdateForm";
import DefaultWholesaleMarketplaceAllocationUpdateForm from "./DefaultWholesaleMarketplaceAllocationUpdateForm";
import ProductManualCreateForm, {languages} from "./ProductManualCreateForm";
import ProductManualUpdateForm from './ProductManualUpdateForm';
import QrCodeRenderer from './QrCodeRenderer';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import MarketplaceCategoriesList from "./MarketplaceCategoriesList";
import StyleIcon from '@mui/icons-material/Style';
import {useEffect, useState} from "react";
import DateRangeIcon from '@mui/icons-material/DateRange';
import MarketplaceAllocationUpdateForm from "./MarketplaceAllocationUpdateForm";
import ContentEditButton from "./attribute/ContentEditButton";
import ListOfAttributes from "./attribute/ListOfAttributes";
import VariantsProductEditTab from "./VariantsProductEditTab";
import ProductGallery from "./ProductGallery";
import ProductSizeAndColorGallery from "./ProductSizeAndColorGallery";
import ListOfCompatibleProducts from "./compatibility/ListOfCompatibleProducts";
import {hasAccess} from "ra-auth-acl";

const ProductEdit = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const [tags, setTags] = useState<ProductTag[]>([]);
    const [loading, setLoading] = useState(true);
    const refresh = useRefresh();
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'product.edit');

    useEffect(() => {
        dataProvider.getList(`products/tags/collection`, {
            filter: [],
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 1000},
            meta: {}
        })
            .then(({data}) => {
                setTags(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return null;
    }

    return (
        <Edit
            aside={<Aside/>}
            mutationMode={`optimistic`}
            redirect={false}
            sx={{
                '& .RaEdit-main': {display: isSmall ? 'block' : 'flex'},
            }}
        >
            <SimpleForm toolbar={false}>
                <Box flex="1" width={`100%`}>
                    <FormDataConsumer>
                        {({formData}) => {
                            const record = formData;
                            useDefineAppLocation('catalog.products.edit', {record});

                            return <React.Fragment>
                                <Box display="flex" mb={1}>
                                    <Box
                                        component="img"
                                        src={record.image}
                                        alt={record.name}
                                        title={record.name}
                                        width={60}
                                        height={60}
                                        sx={{objectFit: 'contain'}}
                                    />
                                    <Box ml={2} flex="1">
                                        <Typography variant="h4">
                                            {record.name}
                                        </Typography>
                                        <Typography variant="body2">
                                            <TextField source="memo"/>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Numbers/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record.sku}
                                                secondary={translate('resources.products.show.sku')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <ColorLens/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record?.color ?? 'n/a'}
                                                secondary={translate('resources.products.show.color')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Straighten/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record?.size ?? 'n/a'}
                                                secondary={translate('resources.products.show.size')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                </Box>

                                <Card sx={{flex: 1, marginTop: "0px", border: 'none'}}>
                                    <List dense={true}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <QrCode2/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record?.ean}
                                                secondary={translate('resources.products.show.ean')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Percent/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={Number(record?.regularDiscount / 100).toLocaleString('de-DE', {
                                                    style: 'percent',
                                                    minimumFractionDigits: 2
                                                })}
                                                secondary={translate('resources.products.show.regular_discount')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                        <ListItem>
                                            <ListItemIcon>
                                                <Percent/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={Number(record?.distriDiscount / 100).toLocaleString('de-DE', {
                                                    style: 'percent',
                                                    minimumFractionDigits: 2
                                                })}
                                                secondary={translate('resources.products.show.distributor_discount')}
                                            />
                                        </ListItem>

                                        {record.phasingOutDate ? <Divider/> : null}
                                        {record.phasingOutDate ? <ListItem>
                                            <ListItemIcon>
                                                <DateRangeIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<DateField source="phasingOutDate"/>}
                                                secondary={translate('resources.products.show.phased_out_date')}
                                            />
                                        </ListItem> : null}

                                        {record.tags.length > 0 ? <Divider/> : null}
                                        {record.tags.length > 0 ?
                                            <ListItem>
                                                <ListItemIcon>
                                                    <StyleIcon/>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<Stack direction="row" gap={1} flexWrap="wrap"
                                                                    title={'Tags'}>
                                                        {record.tags.map((tag, key) => {
                                                            const data = tags.find(t => t.id === tag.tagId);

                                                            if (data === undefined) {
                                                                return null;
                                                            }

                                                            return (
                                                                <Chip
                                                                    size="small"
                                                                    key={tag.tagId}
                                                                    label={data.name}
                                                                />
                                                            );
                                                        })}
                                                    </Stack>}
                                                />
                                            </ListItem> : null}
                                    </List>
                                    <AccordionSection
                                        label={'resources.products.show.marketplace_allocations'}
                                        fullWidth
                                    >
                                        <RaList
                                            resource={`product-marketplace-allocations`}
                                            disableSyncWithLocation={true}
                                            filter={{product: record.id, marketplace: undefined}}
                                            pagination={false}
                                            hasCreate={editable}
                                            empty={false}
                                            sx={{'& .RaList-content': {border: 'none'}}}
                                            actions={editable ?
                                                <MarketplaceAllocationCreateForm record={record as Product}/> : false}
                                        >
                                            <Datagrid
                                                bulkActionButtons={false}
                                                rowClick={editable ? 'expand' : false}
                                                expand={editable ? <MarketplaceCategoriesList
                                                    product={record as Product}/> : undefined}
                                                expandSingle
                                            >
                                                <TextField source="marketplace.name" sortable={false}
                                                           label="resources.products.show.marketplace_name"/>
                                                <FunctionField
                                                    source="netPrice"
                                                    label="resources.products.show.net_price"
                                                    sortable={false}
                                                    textAlign={`right`}
                                                    render={(price: ProductMarketplaceAllocation) => formatPrice(price.netPrice, price.currency, 2, 5)}
                                                />
                                                <FunctionField
                                                    source="promoNetPrice"
                                                    label="resources.products.show.promo_price"
                                                    sortable={false}
                                                    textAlign={`right`}
                                                    render={(price: ProductMarketplaceAllocation) => price.promoNetPrice ? formatPrice(price.promoNetPrice, price.currency, 2, 5) : 'n/a'}
                                                />
                                                <FunctionField
                                                    source="fullPrice"
                                                    label="resources.products.show.full_price"
                                                    sortable={false}
                                                    textAlign={`right`}
                                                    render={(price: ProductMarketplaceAllocation) => formatPrice(price.fullPrice, price.currency, 2, 2)}
                                                />
                                                <NumberField source="minInventory" sortable={false}
                                                             label="resources.products.show.min_inventory"/>
                                                <BooleanField source="isActive" sortable={false}
                                                              label="resources.products.show.is_active"/>
                                                {
                                                    editable ?
                                                        <FunctionField
                                                            source=""
                                                            label=""
                                                            sortable={false}
                                                            textAlign={`right`}
                                                            render={(allocation: ProductMarketplaceAllocation) => allocation.marketplace.isDefault ?
                                                                allocation.marketplace.isRetail ?
                                                                    <DefaultMarketplaceAllocationUpdateForm
                                                                        record={record as Product}/> :
                                                                    <DefaultWholesaleMarketplaceAllocationUpdateForm
                                                                        record={record as Product}/>
                                                                :
                                                                <MarketplaceAllocationUpdateForm
                                                                    record={record as Product}/>}
                                                        />
                                                        : null
                                                }
                                                {
                                                    editable ?
                                                        <FunctionField
                                                            source=""
                                                            label=""
                                                            sortable={false}
                                                            textAlign={`right`}
                                                            render={(allocation: ProductMarketplaceAllocation) => allocation.marketplace.isDefault ? '' :
                                                                <DeleteWithConfirmButton
                                                                    redirect={false}
                                                                    mutationMode="optimistic"
                                                                    translateOptions={{name: allocation.marketplace.name}}
                                                                    mutationOptions={{
                                                                        onSuccess: () => {
                                                                            refresh();
                                                                        }
                                                                    }}
                                                                />}
                                                        />
                                                        : null
                                                }
                                            </Datagrid>
                                        </RaList>
                                    </AccordionSection>
                                    <VariantsProductEditTab record={record as Product} editable={editable}/>
                                    <AccordionSection
                                        label={'resources.products.show.manuals'}
                                        fullWidth
                                    >
                                        <QrCodeRenderer/>
                                        <Divider sx={{margin: '10px'}}/>
                                        <RaList
                                            resource={`product-manuals`}
                                            filter={{product: record.id}}
                                            pagination={false}
                                            hasCreate={editable}
                                            empty={false}
                                            sx={{'& .RaList-content': {border: 'none'}}}
                                            actions={editable ?
                                                <ProductManualCreateForm record={record as Product}/> : false}
                                        >
                                            <Datagrid bulkActionButtons={false}>
                                                <FunctionField
                                                    label="resources.products.show.manual_language"
                                                    source="language"
                                                    sortable={false}
                                                    textAlign={`right`}
                                                    render={(productManual: any) => languages[productManual.language]}
                                                />
                                                <TextField source="type" sortable={false}
                                                           label="resources.products.show.manual_type"/>
                                                <UrlField source="url" sortable={false}
                                                          label="resources.products.show.manual_source_file"/>
                                                <NumberField source="readCount" sortable={false}
                                                             label="resources.products.show.manual_scans"/>
                                                {
                                                    editable ?
                                                        <ProductManualUpdateForm record={record as Product}/>
                                                        : null
                                                }
                                                {
                                                    editable ?
                                                        <DeleteWithConfirmButton
                                                            redirect={false}
                                                            confirmTitle="resources.products.show.manual_file_delete_title"
                                                            mutationOptions={{
                                                                onSuccess: () => {
                                                                    refresh();
                                                                }
                                                            }}
                                                        />
                                                        : null
                                                }
                                            </Datagrid>
                                        </RaList>
                                    </AccordionSection>
                                    <AccordionSection
                                        label={'resources.products.show.product_content'}
                                        fullWidth
                                    >
                                        <Show
                                            aside={
                                                editable ? <Box sx={{paddingTop: '30px'}}>
                                                    <ContentEditButton/>
                                                </Box> : undefined
                                            }
                                            resource={`products/content`}
                                            actions={false}
                                            sx={{'& .MuiPaper-root.RaShow-card': {border: 'none'}}}
                                        >
                                            <SimpleShowLayout>
                                                <TranslatableFields locales={['de', 'en', 'fr']}>
                                                    <TextField source="name_translatable"
                                                               label="resources.products.show.content.name"/>
                                                    <TextField source="description_translatable"
                                                               label="resources.products.show.content.description"/>
                                                    <TextField source="deliveryTime_translatable"
                                                               label="resources.products.show.content.delivery_time"/>
                                                    <TextField source="sizeDescription_translatable"
                                                               label="resources.products.show.content.size"/>
                                                    <TextField source="slug_translatable"
                                                               label="resources.products.show.content.slug"/>
                                                    <UrlField source="oldUrl_translatable"
                                                              label="resources.products.show.content.url"/>
                                                    <TextField source="metaTitle_translatable"
                                                               label="resources.products.show.content.meta_title"/>
                                                    <TextField source="metaDescription_translatable"
                                                               label="resources.products.show.content.meta_description"/>

                                                    <FunctionField
                                                        source="videos_translatable"
                                                        label="resources.products.show.content.videoTranslatable"
                                                        render={(record, source) => {
                                                            const lang = source.split('.').pop();

                                                            return (
                                                                <List dense
                                                                      sx={{width: '100%', bgcolor: 'background.paper'}}>
                                                                    {(record?.videos_translatable[lang] && Array.isArray(record.videos_translatable[lang])) ? record.videos_translatable[lang].map((value) => {
                                                                        const labelId = `gallery-list-secondary-label-${value.key}`;
                                                                        return (
                                                                            <ListItem
                                                                                key={value.key}
                                                                                disablePadding
                                                                            >
                                                                                <ListItemButton
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        window.open(value.url, '_blank');
                                                                                    }}>
                                                                                    <ListItemAvatar>
                                                                                        <Avatar
                                                                                            alt={value.title}
                                                                                            src={value.thumbnail}
                                                                                        />
                                                                                    </ListItemAvatar>
                                                                                    <ListItemText id={labelId}
                                                                                                  primary={value.url}/>
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        );
                                                                    }) : ''}
                                                                </List>
                                                            );
                                                        }}
                                                    />
                                                </TranslatableFields>

                                            </SimpleShowLayout>
                                        </Show>
                                    </AccordionSection>
                                    <AccordionSection
                                        label={'resources.products.show.product_media'}
                                        fullWidth
                                    >
                                        <Show
                                            resource={`products/media`}
                                            actions={false}
                                        >
                                            <SimpleShowLayout>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={3}>
                                                        <span style={{color: "rgba(0, 0, 0, 0.6)", fontSize: "0.75em"}}>
                                                            {translate('resources.products.show.color_image')}
                                                        </span>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <span style={{color: "rgba(0, 0, 0, 0.6)", fontSize: "0.75em"}}>
                                                            {translate('resources.products.show.size_image')}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <ProductSizeAndColorGallery editable={editable} />
                                                <hr/>
                                                <ArrayField
                                                    source="media"
                                                    label="resources.products.show.content.gallery"
                                                >
                                                    <WithListContext render={({data}) => (
                                                        <ProductGallery rawImages={data} record={record} editable={editable} />
                                                    )}/>
                                                </ArrayField>
                                            </SimpleShowLayout>
                                        </Show>
                                    </AccordionSection>
                                    <ListOfAttributes record={record as Product} editable={editable}/>

                                    {record.tags.map((tag: { tagId: string }) => {
                                        switch (tag.tagId) {
                                            case 'trailer':
                                                return <>
                                                    <ListOfCompatibleProducts
                                                        key={`${tag.tagId}_1`}
                                                        record={record as Product}
                                                        label={'resources.products.show.compatible_accessories'}
                                                        type="accessory"
                                                        editable={editable}
                                                    />
                                                    <ListOfCompatibleProducts
                                                        key={`${tag.tagId}_2`}
                                                        record={record as Product}
                                                        label={'resources.products.show.compatible_spare_parts'}
                                                        type="spare_part"
                                                        editable={editable}
                                                    />
                                                </>;
                                            case 'accessory':
                                            case 'spare_part':
                                                return <>
                                                    <ListOfCompatibleProducts
                                                        key={`${tag.tagId}_1`}
                                                        record={record as Product}
                                                        label={'resources.products.show.compatible_trailers'}
                                                        type="trailer"
                                                        editable={editable}
                                                    />
                                                    <ListOfCompatibleProducts
                                                        key={`${tag.tagId}_2`}
                                                        record={record as Product}
                                                        label={'resources.products.show.compatible_accessories'}
                                                        type="accessory"
                                                        editable={editable}
                                                    />
                                                    <ListOfCompatibleProducts
                                                        key={`c_${tag.tagId}_3`}
                                                        record={record as Product}
                                                        label={'resources.products.show.compatible_spare_parts'}
                                                        type="spare_part"
                                                        editable={editable}
                                                    />
                                                </>;
                                            default:
                                                return null;
                                        }
                                    })}
                                </Card>
                            </React.Fragment>;
                        }}
                    </FormDataConsumer>
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export default ProductEdit;
