import * as React from 'react';

import {
    FunctionField,
    Edit,
    SimpleForm,
    useTranslate,
    TextField,
    List,
    FormDataConsumer,
    Labeled,
    useRecordContext,
    SelectArrayInput,
    SimpleShowLayout, usePermissions
} from 'react-admin';
import {
    Box,
    Typography,
    Card,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    useMediaQuery,
    Theme, Grid, Stack, Chip
} from '@mui/material';

import Aside from './Aside';
import {
    Numbers,
    ToggleOn,
    Language,
    Group,
    Category,
    EmojiPeople,
    Lock
} from "@mui/icons-material";
import {AccordionSection, ShowInDialogButton} from "@react-admin/ra-form-layout";
import {Dealer, DealerAddress} from "../types";
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import countryCodes, {CountryProperty} from "country-codes-list";
import {FieldValues} from "react-hook-form";
import HandymanIcon from '@mui/icons-material/Handyman';
import {EditableDatagrid, EditRowButton, RowForm} from "@react-admin/ra-editable-datagrid";
import {hasAccess} from "ra-auth-acl";

export const FUNCTIONS = {
    'PICKUP_POINT': {
        id: 'PICKUP_POINT',
        name: 'resources.dealers.address.pickup_point',
        color: 'primary'
    },
    'SERVICE_PARTNER': {
        id: 'SERVICE_PARTNER',
        name: 'resources.dealers.address.service_point',
        color: 'secondary'
    },
    'PHYSICAL_STORE': {
        id: 'PHYSICAL_STORE',
        name: 'resources.dealers.address.physical_store',
        color: 'success'
    }
};

const AddressSection = (props: { dealer: FieldValues }) => {
    const {dealer} = props;

    if (!dealer) {
        return null;
    }
    let countryName: string = '';
    let country: { countryNameEn?: string; flag?: string } = {flag: ''};

    if (dealer?.country) {
        country = countryCodes.findOne('countryCode' as CountryProperty, dealer.country);
        countryName = country?.countryNameEn ?? '';
    }

    return (
        <div>
            <Typography>
                {dealer?.firstName ? `${dealer.firstName} ${dealer.lastName}` : dealer.name}
            </Typography>
            <Typography>{dealer?.company && dealer.company.trim().length > 0 ? dealer.company.trim() : ''}</Typography>
            <Typography>
                {dealer?.street}
            </Typography>
            <Typography>
                {dealer?.zipCode}, {dealer?.city}
            </Typography>
            <Typography>
                {countryName} {country?.flag}
            </Typography>
        </div>
    );
};
const ContactSection = (props: { dealer: FieldValues }) => {
    const {dealer} = props;

    if (!dealer) {
        return null;
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Labeled
                        label={`resources.dealers.show.phoneNumber1`}
                    >
                        <Typography>
                            {dealer?.phoneNumber1 ? dealer.phoneNumber1 : 'n/a'}
                        </Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Labeled
                        label={`resources.dealers.show.phoneNumber2`}
                    >
                        <Typography>
                            {dealer?.phoneNumber2 ? dealer.phoneNumber2 : 'n/a'}
                        </Typography>
                    </Labeled>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    {
                        dealer?.phoneNumber3 && <Labeled
                            label={`resources.dealers.show.phoneNumber3`}
                        >
                            <Typography>
                                {dealer?.phoneNumber3}
                            </Typography>
                        </Labeled>
                    }
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {
                        dealer?.phoneNumber4 && <Labeled
                            label={`resources.dealers.show.phoneNumber4`}
                        >
                            <Typography>
                                {dealer?.phoneNumber4}
                            </Typography>
                        </Labeled>
                    }
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Labeled
                        label={`resources.dealers.show.email`}
                    >
                        <Typography>
                            {dealer?.email ? dealer.email : 'n/a'}
                        </Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Labeled
                        label={`resources.dealers.show.email2`}
                    >
                        <Typography>
                            {dealer?.email2 ? dealer.email2 : 'n/a'}
                        </Typography>
                    </Labeled>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Labeled
                        label={`resources.dealers.show.website`}
                    >
                        <Typography>
                            {dealer?.website ? dealer.website : 'n/a'}
                        </Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {
                        dealer?.faxNumber && <Labeled
                            label={`resources.dealers.show.faxNumber`}
                        >
                            <Typography>
                                {dealer.faxNumber}
                            </Typography>
                        </Labeled>
                    }
                </Grid>
            </Grid>


        </div>
    );
};
const AddressEditForm = () => {
    return (
        <RowForm>
            <TextField
                source="selectLineId"
                key="selectLineId"
                label={`resources.dealers.address.selectLineId`}
                sortable={false}
            />
            <TextField
                source="firstName"
                key="firstName"
                label={`resources.dealers.address.firstName`}
                sortable={false}
            />
            <TextField
                source="lastName"
                key="lastName"
                label={`resources.dealers.address.lastName`}
                sortable={false}
            />
            <TextField
                source="street"
                key="street"
                label={`resources.dealers.address.street`}
                sortable={false}
            />
            <TextField
                source="city"
                key="city"
                label={`resources.dealers.address.city`}
                sortable={false}
            />
            <TextField
                source="zipCode"
                key="zipCode"
                label={`resources.dealers.address.zipCode`}
                sortable={false}
            />
            <TextField
                source="addressLine1"
                key="addressLine1"
                label={`resources.dealers.address.addressLine1`}
                sortable={false}
            />
            <SelectArrayInput
                source="functions"
                name="functions"
                choices={[
                    FUNCTIONS['PHYSICAL_STORE'],
                    FUNCTIONS['PICKUP_POINT'],
                    FUNCTIONS['SERVICE_PARTNER']
                ]}
            />
        </RowForm>
    );
};
const AddressLineButtons = () => {
    const translate = useTranslate();
    const {permissions} = usePermissions();

    const record = useRecordContext<DealerAddress>();
    const query = encodeURIComponent(record.street) + ',' + encodeURIComponent(record.zipCode) + ' ' + encodeURIComponent(record.city);

    let src: string = `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}&q=${query}`

    if (record.lat && record.lon) {
        src = `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}&q=${query}&center=${record.lat},${record.lon}`
    }

    return (
        <>
            {hasAccess(permissions, 'dealer.edit') ? <EditRowButton/> : null}
            <ShowInDialogButton fullWidth maxWidth="md" title={'Address'}>
                <SimpleShowLayout>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="selectLineId"
                                label={`resources.dealers.address.selectLineId`}
                            >
                                <TextField source="selectLineId" key="selectLineId"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="salutation"
                                label={`resources.dealers.address.salutation`}
                            >
                                <TextField source="salutation" key="salutation"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="firstName"
                                label={`resources.dealers.address.firstName`}
                            >
                                <TextField source="firstName" key="firstName"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="lastName"
                                label={`resources.dealers.address.lastName`}
                            >
                                <TextField source="lastName" key="lastName"/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="street"
                                label={`resources.dealers.address.street`}
                            >
                                <TextField source="street" key="street"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="zipCode"
                                label={`resources.dealers.address.zipCode`}
                            >
                                <TextField source="zipCode" key="zipCode"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="city"
                                label={`resources.dealers.address.city`}
                            >
                                <TextField source="city" key="city"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="country"
                                label={`resources.dealers.address.country`}
                            >
                                <TextField source="country" key="country"/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="addressLine1"
                                label={`resources.dealers.address.addressLine1`}
                            >
                                <TextField source="addressLine1" key="addressLine1" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="addressLine2"
                                label={`resources.dealers.address.addressLine2`}
                            >
                                <TextField source="addressLine2" key="addressLine2" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Labeled
                                source="addressLine3"
                                label={`resources.dealers.address.addressLine3`}
                            >
                                <TextField source="addressLine3" key="addressLine3" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="email"
                                label={`resources.dealers.address.email`}
                            >
                                <TextField source="email" key="email" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="email2"
                                label={`resources.dealers.address.email2`}
                            >
                                <TextField source="email2" key="email2" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="website"
                                label={`resources.dealers.address.website`}
                            >
                                <TextField source="website" key="website" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="faxNumber"
                                label={`resources.dealers.address.faxNumber`}
                            >
                                <TextField source="faxNumber" key="faxNumber" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="phoneNumber1"
                                label={`resources.dealers.address.phoneNumber1`}
                            >
                                <TextField source="phoneNumber1" key="phoneNumber1" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="phoneNumber2"
                                label={`resources.dealers.address.phoneNumber2`}
                            >
                                <TextField source="phoneNumber2" key="phoneNumber2" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="phoneNumber3"
                                label={`resources.dealers.address.phoneNumber3`}
                            >
                                <TextField source="phoneNumber3" key="phoneNumber3" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="phoneNumber4"
                                label={`resources.dealers.address.phoneNumber4`}
                            >
                                <TextField source="phoneNumber4" key="phoneNumber4" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="erpFunction"
                                label={`resources.dealers.address.function`}
                            >
                                <TextField source="erpFunction" key="erpFunction" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="department"
                                label={`resources.dealers.address.department`}
                            >
                                <TextField source="department" key="department" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="functions"
                                label={`resources.dealers.address.functions`}
                            >
                                <FunctionField
                                    source="functions"
                                    emptyText={`n/a`}
                                    label={`resources.dealers.address.functions`}
                                    render={(dealer: Dealer) => (
                                        <Stack direction="row" gap={1} flexWrap="wrap">
                                            {dealer.functions.length ? dealer.functions.map((funct, key) => {
                                                const _function = FUNCTIONS[funct];
                                                return (
                                                    <Chip
                                                        size="small"
                                                        key={`${funct}-${key}`}
                                                        label={translate(_function.name)}
                                                        color={_function.color}
                                                    />
                                                );
                                            }) : 'n/a'}
                                        </Stack>)}
                                    sortable={false}
                                />
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="lat"
                                label={`resources.dealers.address.latitude`}
                            >
                                <TextField source="lat" key="lat" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <Labeled
                                source="lon"
                                label={`resources.dealers.address.longitude`}
                            >
                                <TextField source="lon" key="lon" emptyText={`n/a`}/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <iframe
                        width="100%"
                        height="300"
                        loading="lazy" style={{margin: 'auto'}}
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src={src}>
                    </iframe>
                </SimpleShowLayout>
            </ShowInDialogButton>
        </>
    );
};

const DealerEdit = () => {
    const translate = useTranslate();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const languageName = (code: string) => {
        let returnedString: string = code;

        const country = countryCodes.findOne('countryCode' as CountryProperty, code.toUpperCase());

        if (country) {
            returnedString = `${country.flag} ${country.officialLanguageNameEn}`;
        }

        return <>
            {returnedString}
        </>;
    }

    return (
        <Edit
            aside={<Aside/>}
            mutationMode={`optimistic`}
            redirect={false}
            sx={{
                '& .RaEdit-main': {display: isSmall ? 'block' : 'flex'},
            }}
        >
            <SimpleForm toolbar={false}>
                <Box flex="1" width={`100%`}>
                    <FormDataConsumer>
                        {({formData}) => {
                            const record: FieldValues = formData;
                            useDefineAppLocation('settings.dealers.edit', {record});

                            return <React.Fragment>
                                <Box display="flex" mb={1}>
                                    <Box ml={2} flex="1">
                                        <Typography variant="h4">
                                            {record.name}
                                        </Typography>
                                        <Typography variant="body2">
                                            #<TextField source="selectLineId"/>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Group/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record.groupNumber}
                                                secondary={record.groupName ?? translate('resources.dealers.show.groupNumber')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Group/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record.accountGroupNumber}
                                                secondary={record.accountGroupName ?? translate('resources.dealers.show.accountGroupNumber')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Numbers/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record.taxIdentifierNumber ? record.taxIdentifierNumber : 'n/a'}
                                                secondary={translate('resources.dealers.show.tax')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Language/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={languageName(record.language ?? '')}
                                                secondary={translate('resources.dealers.show.language')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <ToggleOn/>
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{color: record.isActive ? 'green' : 'red'}}
                                                primary={translate(record.isActive ? 'ra.boolean.true' : 'ra.boolean.false')}
                                                secondary={translate('resources.dealers.show.isActive')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <HandymanIcon/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={translate(record.isServicePartner ? 'ra.boolean.true' : 'ra.boolean.false')}
                                                secondary={translate('resources.dealers.show.isServicePartner')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Category/>
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={record.industry ?? 'n/a'}
                                                secondary={translate('resources.dealers.show.industry')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                    <Box ml={2} flex="1">
                                        <ListItem>
                                            <ListItemIcon>
                                                <Lock/>
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{color: record.isDocumentLock ? 'red' : 'green'}}
                                                primary={
                                                    `${translate(record.isDocumentLock ? 'ra.boolean.true' : 'ra.boolean.false') + (record.isDocumentLock ? `: ${record?.lockReason}` : '')}`
                                                }
                                                secondary={translate('resources.dealers.show.isDocumentLock')}
                                            />
                                        </ListItem>
                                        <Divider/>
                                    </Box>
                                </Box>

                                <Card sx={{flex: 1, marginTop: "20px", border: 'none'}}>
                                    <AccordionSection
                                        label={`resources.dealers.show.address_contact`}
                                        fullWidth
                                        defaultExpanded={true}
                                    >
                                        <Box display="flex">
                                            <Box flex="1">
                                                <Typography variant="h6" gutterBottom>
                                                    {translate('resources.dealers.show.address')}
                                                </Typography>
                                                <AddressSection dealer={record}/>
                                            </Box>
                                            <Box ml={2} flex="1">
                                                <Typography variant="h6" gutterBottom>
                                                    {translate('resources.dealers.show.contact')}
                                                </Typography>
                                                <ContactSection dealer={record}/>
                                            </Box>
                                        </Box>
                                    </AccordionSection>

                                    <AccordionSection
                                        label={`resources.dealers.show.locations`}
                                        fullWidth
                                    >
                                        <List
                                            resource={`dealer-addresses`}
                                            disableSyncWithLocation={true}
                                            filter={{dealer: record.id}}
                                            pagination={false}
                                            hasCreate={false}
                                            exporter={false}
                                            empty={false}
                                            sx={{'& .RaList-content': {border: 'none'}}}
                                            perPage={1000}
                                        >
                                            <EditableDatagrid
                                                actions={<AddressLineButtons/>}
                                                mutationMode="undoable"
                                                editForm={<AddressEditForm/>}
                                                bulkActionButtons={false}
                                                rowClick={false}
                                            >
                                                <TextField
                                                    source="selectLineId"
                                                    key="selectLineId"
                                                    label={`resources.dealers.address.selectLineId`}
                                                    sortable={false}
                                                />
                                                <TextField
                                                    source="firstName"
                                                    key="firstName"
                                                    label={`resources.dealers.address.firstName`}
                                                    sortable={false}
                                                />
                                                <TextField
                                                    source="lastName"
                                                    key="lastName"
                                                    label={`resources.dealers.address.lastName`}
                                                    sortable={false}
                                                />
                                                <TextField
                                                    source="street"
                                                    key="street"
                                                    label={`resources.dealers.address.street`}
                                                    sortable={false}
                                                />
                                                <TextField
                                                    source="city"
                                                    key="city"
                                                    label={`resources.dealers.address.city`}
                                                    sortable={false}
                                                />
                                                <TextField
                                                    source="zipCode"
                                                    key="zipCode"
                                                    label={`resources.dealers.address.zipCode`}
                                                    sortable={false}
                                                />
                                                <TextField
                                                    source="addressLine1"
                                                    key="addressLine1"
                                                    label={`resources.dealers.address.addressLine1`}
                                                    sortable={false}
                                                />
                                                <FunctionField
                                                    source="functions"
                                                    label={`resources.dealers.address.functions`}
                                                    render={(dealer: Dealer) => (
                                                        <Stack direction="row" gap={1} flexWrap="wrap">
                                                            {dealer.functions.map((funct, key) => {
                                                                const _function = FUNCTIONS[funct];
                                                                return (
                                                                    <Chip
                                                                        size="small"
                                                                        key={`${funct}-${key}`}
                                                                        label={translate(_function.name)}
                                                                        color={_function.color}
                                                                    />
                                                                );
                                                            })}
                                                        </Stack>)}
                                                    sortable={false}
                                                />
                                            </EditableDatagrid>
                                        </List>
                                    </AccordionSection>
                                </Card>
                            </React.Fragment>;
                        }}
                    </FormDataConsumer>
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export default DealerEdit;
