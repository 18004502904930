import React, {Fragment} from 'react';
import {Grid, Box, useMediaQuery, Theme} from '@mui/material';
import {useGetList} from 'react-admin';

import {Logo} from '../layout/Logo';
import WeeklyRevenueChart from './WeeklyRevenueChart';
import MonthlyRevenueChart from './MonthlyRevenueChart';
import BestSellingReport from "./BestSellingReport";
import DailyRevenue from './DailyRevenue';
import {QuickViewReport} from "../utils/types";

interface MarketplaceDailyRevenue {
    marketplace: string;
    revenue: string;
    orders: number;
}

const Spacer = () => <span style={{width: '1em'}}/>;
const Dashboard = () => {
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const styles = {
        flex: {display: 'flex'},
        flexColumn: {display: 'flex', flexDirection: 'column'},
        leftCol: {flex: 1, marginRight: '0.5em'},
        rightCol: {flex: 1, marginLeft: '0.5em'},
        singleCol: {marginTop: '1em', marginBottom: '1em'},
    };

    const {data: stats, isLoading} = useGetList('quick-view-reports', {
        pagination: {page: 1, perPage: 50},
    });

    if (isLoading) {
        return null;
    }

    const weeklyRevenueReport = (stats as QuickViewReport[]).find((stat: any) => stat.code === 'WEEKLY_REVENUE');
    const monthlyRevenueReport = (stats as QuickViewReport[]).find((stat: any) => stat.code === 'MONTHLY_REVENUE');
    const bestSellersReport = (stats as QuickViewReport[]).find((stat: any) => stat.code === 'BEST_SELLERS');
    const dailyRevenueReport = (stats as QuickViewReport[]).find((stat: any) => stat.code === 'DAILY_REVENUE');

    return isSmall ? (
        <Grid container spacing={1}>
            <Box ml={1}>
                <Logo/>
            </Box>
            <Grid item xs={12}>
                {dailyRevenueReport ?
                    (dailyRevenueReport.data as MarketplaceDailyRevenue[]).map((item: MarketplaceDailyRevenue, index) => {
                        return (
                            <div key={index} style={{marginBottom: 10}}>
                                <DailyRevenue
                                    money={item.revenue}
                                    quantity={item.orders}
                                    name={item.marketplace}
                                    updatedAt={dailyRevenueReport.updatedAt}
                                />
                                {dailyRevenueReport.data.indexOf(item) !== dailyRevenueReport.data.length - 1 &&
                                    <Spacer/>}
                            </div>
                        );
                    }) : null
                }
                <div style={{marginBottom: 20}}>
                    <WeeklyRevenueChart stats={weeklyRevenueReport}/>
                </div>
                <div style={{marginBottom: 20}}>
                    <BestSellingReport stats={bestSellersReport}/>
                </div>
                <div style={{marginBottom: 20}}>
                    <MonthlyRevenueChart stats={monthlyRevenueReport}/>
                </div>
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={2} padding={1}>
            <Box mt={2} ml={-3} display="flex">
                <Logo/>
            </Box>

            <Grid item xs={12}>
                <div style={styles.flex}>
                    {dailyRevenueReport ?
                        (dailyRevenueReport.data as MarketplaceDailyRevenue[]).map((item: MarketplaceDailyRevenue, index) => {
                            return (
                                <Fragment key={index}>
                                    <DailyRevenue
                                        money={item.revenue}
                                        quantity={item.orders}
                                        name={item.marketplace}
                                        updatedAt={dailyRevenueReport.updatedAt}
                                    />
                                    {dailyRevenueReport.data.indexOf(item) !== dailyRevenueReport.data.length - 1 &&
                                        <Spacer/>}
                                </Fragment>
                            );
                        }) : null
                    }
                </div>
            </Grid>
            <Grid item xs={12}>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <div style={styles.flex}>

                        </div>
                        <div style={styles.singleCol}>
                            <WeeklyRevenueChart stats={weeklyRevenueReport}/>
                        </div>
                        <div style={styles.singleCol}>
                            <MonthlyRevenueChart stats={monthlyRevenueReport}/>
                        </div>
                    </div>
                    <div style={styles.rightCol}>
                        <div style={styles.singleCol}>
                            <BestSellingReport stats={bestSellersReport}/>
                        </div>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
