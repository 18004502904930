import * as React from 'react';
import {
    Datagrid,
    DateField,
    useRecordContext,
    useTranslate,
    BooleanField, ArrayField, List, TextField, NumberField, SimpleShowLayout, UrlField
} from 'react-admin';
import {
    Box,
    Card, CardHeader, Divider,
    Link,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';
import {Report, ReportRequirement} from '../types';
import {FunctionField} from 'ra-ui-materialui';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {useMarketplaces} from "../utils/useMarketplaces";
import {REQUIREMENTS, STATUSES, TYPES} from "./dictionaries";
import ReportFilesList from "./ReportFilesList";
import {MarkdownField} from "@react-admin/ra-markdown";

const ReportShowContainer = () => {
    const translate = useTranslate();
    const record = useRecordContext<Report>();
    const {marketplaces, isLoading} = useMarketplaces();

    useDefineAppLocation('reports.reports_list.show');

    if (!record || isLoading) {
        return null;
    }

    return (
        <Box flex="1" width={`100%`}>
            <Card>
                <Box display="flex" mb={1}>
                    <Box ml={2} flex="1">
                        <Typography variant="h4" mt={2}>
                            {record.name}
                        </Typography>
                        <Typography variant="body2">
                            <FunctionField
                                source="type"
                                key="type"
                                label={`resources.reports.reports.list.type`}
                                render={(report: Report) => translate(TYPES[report.type]['name'])}
                            />
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex="1">
                        <ListItem>
                            <ListItemText
                                primary={<FunctionField
                                    source="frequency"
                                    key="frequency"
                                    label={`resources.reports.reports.list.frequency`}
                                    render={(report: Report) => {
                                        if (report.frequency === 'ONE_TIME') {
                                            return translate('resources.reports.reports.frequency.one_time');
                                        }

                                        return translate('resources.reports.reports.frequency.recurring');
                                    }}
                                />}
                                secondary={translate('resources.reports.reports.list.frequency')}
                            />
                        </ListItem>
                    </Box>
                    <Box ml={2} flex="1">
                        <ListItem>
                            <ListItemText
                                primary={<FunctionField
                                    source="status"
                                    key="status"
                                    label={`resources.reports.reports.list.status`}
                                    render={(report: Report) => {
                                        const _status = STATUSES[report.status];

                                        return translate(_status.name);
                                    }}
                                />}
                                secondary={translate('resources.reports.reports.list.status')}
                            />
                        </ListItem>
                    </Box>
                    <Box ml={2} flex="1">
                        <ListItem>
                            <ListItemText
                                primary={<BooleanField
                                    source="isPrivate"
                                    key="isPrivate"
                                    label={`resources.reports.reports.list.isPrivate`}
                                />}
                                secondary={translate('resources.reports.reports.list.isPrivate')}
                            />
                        </ListItem>
                    </Box>
                    <Box flex="1">
                        <ListItem>
                            <ListItemText
                                primary={<FunctionField
                                    source="creator"
                                    key="creator"
                                    label={`resources.reports.reports.list.createdBy`}
                                    render={(report: Report) => {
                                        const creator = report.creator;

                                        return (creator.owner ? `${creator.name}` :
                                            <Link
                                                href={`mailto:${creator.email}`}
                                                onClick={e => e.stopPropagation()}
                                                variant="body2"
                                            >
                                                {creator.name}
                                            </Link>);
                                    }}
                                />}
                                secondary={translate('resources.reports.reports.list.createdBy')}
                            />
                        </ListItem>
                    </Box>
                    <Box ml={2} flex="1">
                        <ListItem>
                            <ListItemText
                                primary={<DateField
                                    source="createdAt"
                                    label={`resources.reports.reports.files.created_at`}
                                    locales="de-DE"
                                    options={{
                                        weekday: undefined,
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    }}
                                    sortable={false}
                                    showTime={true}
                                />}
                                secondary={translate('resources.reports.reports.files.created_at')}
                            />
                        </ListItem>
                    </Box>
                </Box>
                <Divider/>
                <Box display="flex">
                    <ListItem>
                        <ListItemText primary={<MarkdownField source="description" label={` `}/>} />
                    </ListItem>
                </Box>
            </Card>

            <Box display={{sm: 'block', md: 'flex'}} sx={{width: '100%'}}>
                <Box flex="1">
                    <Card sx={{marginTop: 5}}>
                        <CardHeader title={translate('resources.reports.reports.show.listOfRequirements')}/>
                        <ArrayField source="requirements">
                            <Datagrid bulkActionButtons={false} sx={{'& thead': {display: 'none'}}}>
                                <FunctionField
                                    source="name"
                                    key="name"
                                    render={(req: ReportRequirement) => {
                                        if (REQUIREMENTS[req.name]) {
                                            return translate(REQUIREMENTS[req.name].name);
                                        }

                                        return req.name;
                                    }}
                                />
                                <FunctionField
                                    source="value"
                                    key="value"
                                    render={(req: ReportRequirement) => {

                                        const r = REQUIREMENTS[req.name];
                                        const value = {
                                            value: req.value,
                                        };
                                        switch (req.name) {
                                            case 'MARKETPLACES':
                                                value.value = JSON.parse(req.value).map((id) => {
                                                    const marketplace = marketplaces.find(m => m.id === id);
                                                    if (marketplace === undefined) {
                                                        return '';
                                                    }

                                                    return marketplace.name;
                                                }).join(', ');
                                                break;
                                            case 'FREQUENCY_VALUE':
                                            case 'GROUP_BY':
                                                const values = r.values;
                                                value.value = translate(values.find(v => v.id === req.value)['name']);
                                                break;
                                            default:
                                                value.value = req.value;
                                        }

                                        return r.type === 'boolean' ? translate(value.value == '1' ? 'ra.boolean.true' : 'ra.boolean.false') : value.value;
                                    }}
                                />
                            </Datagrid>
                        </ArrayField>
                    </Card>
                </Box>
                <Box ml={2} flex="1">
                    <Card sx={{marginTop: 5}}>
                        <ReportFilesList/>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

export default ReportShowContainer;
