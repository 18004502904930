import * as React from "react";
import {BulkDeleteButton, Datagrid, DeleteWithConfirmButton, FunctionField, List, TextField} from "react-admin";
import {AccordionSection} from "@react-admin/ra-form-layout";
import {Link} from "@mui/material";
import {productNameWithColorAndSize} from "../../utils/productNameWithColorAndSize";
import AddNewCompatibleProducts from "./AddNewCompatibleProducts";
import {Product, ProductMatch} from "../../types";

const ListOfCompatibleProducts = (props: {
    record: Product,
    label: string,
    type: 'trailer' | 'accessory' | 'spare_part',
    editable: boolean
}) => {
    return (
        <AccordionSection label={props.label} fullWidth>
            <List
                resource={`product-matches`}
                filter={{product: props.record.id, type: props.type}}
                perPage={999}
                pagination={false}
                hasCreate={true}
                empty={false}
                sx={{'& .List-content': {border: 'none'}}}
                actions={props.editable ?
                    <AddNewCompatibleProducts record={props.record as Product} type={props.type}/> : false}
            >
                <Datagrid
                    bulkActionButtons={
                        props.editable ? <BulkDeleteButton
                            mutationMode="optimistic"
                            confirmTitle=""
                            confirmContent={`resources.products.show.matched.bulk_delete_content`}
                        /> : false
                    }
                >
                    <FunctionField
                        source="matched.name"
                        label={`resources.products.show.matched.name`}
                        sortable={false}
                        render={(pm: ProductMatch) => (
                            pm.matched?.deletedAt ? productNameWithColorAndSize(pm.matched) :
                                <Link href={`/#/products/${pm.matched.id}`}>
                                    {productNameWithColorAndSize(pm.matched)}
                                </Link>
                        )}
                    />
                    <TextField source="matched.sku" label="resources.products.show.matched.sku" sortable={false}/>
                    {
                        props.editable ?
                            <DeleteWithConfirmButton
                                redirect={false}
                                mutationMode="optimistic"
                                confirmTitle=" "
                            />
                            : null
                    }
                </Datagrid>
            </List>
        </AccordionSection>
    )
};

export default ListOfCompatibleProducts;
