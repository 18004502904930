import * as React from 'react';
import {
    useTranslate,
    useRecordContext,
    Loading,
    useDataProvider,
    useGetList,
    useLocaleState,
    Edit,
    SimpleForm,
    Toolbar,
    BooleanInput,
    SelectInput, usePermissions
} from 'react-admin';
import {
    Typography,
    Card,
    CardContent,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent
} from '@mui/material';
import {Product, Inventory} from '../types';
import InventoryAllocationCard from "./InventoryAllocationCard";
import {useEffect, useState} from "react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import {AutoSave} from '@react-admin/ra-form-layout';
import {FLAGS_CHOICES} from "./FlagField";
import {hasAccess} from "ra-auth-acl";

const Aside = () => {
    const record = useRecordContext<Product>();
    return (
        <Box width={{xs: "100%", md: 300, lg: 400}} sx={{minWidth: 300}}>
            {record && <FlagsComponent record={record}/>}
            {record && <AsideComponent record={record}/>}
            {record && <PurchaseListComponent record={record}/>}
        </Box>
    );
};

const AsideComponent = (props: { record: Product }) => {
    const record = props.record;
    const translate = useTranslate();

    const dataProvider = useDataProvider();

    const [inventory, setInventory] = useState<Inventory | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dataProvider.getOne(`product/${record.id}/inventory`, {id: undefined})
            .then(({data}) => {
                setInventory(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            });
    }, [props]);

    if (inventory === null || loading) {
        return <Loading/>;
    }

    return (
        <Box ml={{md: 2}} mt={2}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.stocks_headline')}
                    </Typography>
                    <InventoryAllocationCard
                        marketplace={translate('resources.products.aside.sale_ready_stock')}
                        quantity={inventory.quantity} theme="secondary"
                    />
                    {inventory.allocations.map(allocation => (
                        <InventoryAllocationCard
                            key={allocation.id}
                            marketplace={allocation.marketplace.name}
                            quantity={allocation.quantity} theme="primary"
                        />
                    ))}
                </CardContent>
            </Card>
        </Box>
    );
};
const FlagsComponent = (props: { record: Product }) => {
    const translate = useTranslate();
    const {permissions} = usePermissions();
    const notEditable = !hasAccess(permissions, 'product.edit');
    return (
        <Box ml={{md: 2}} mt={0}>

            <Edit sx={{'& .RaEdit-main': {marginTop: 0}}} mutationMode="pessimistic" title={undefined}>
                <SimpleForm
                    resetOptions={{keepDirtyValues: true}}
                    toolbar={<Toolbar><AutoSave debounce={1000}/></Toolbar>}
                    record={{isDealerDedicated: props.record.isDealerDedicated, flag: props.record.flag}}
                >
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.settings_headline')}
                    </Typography>

                    <BooleanInput
                        source="isDealerDedicated"
                        fullWidth
                        name="isDealerDedicated"
                        disabled={notEditable}
                    />

                    <SelectInput
                        emptyText={'n/a'}
                        source="flag"
                        fullWidth
                        name="flag"
                        choices={FLAGS_CHOICES}
                        disabled={notEditable}
                    />

                </SimpleForm>
            </Edit>

        </Box>
    );
};
const PurchaseListComponent = (props: { record: Product }) => {
    const record = props.record;
    const translate = useTranslate();
    const [locale, setLocale] = useLocaleState();

    const {data: list, isLoading} = useGetList(`product-purchase-orders`, {
            filter: {product: record.id},
            sort: {field: 'arrivalDate', order: 'ASC'},
            pagination: {page: 1, perPage: 999}
        },
        {staleTime: 30000}
    );

    const types = {
        PURCHASE_ORDER: {
            icon: ShoppingCartIcon,
            label: 'resources.products.aside.purchase'
        },
        PROJECTED_ARRIVAL: {
            icon: DirectionsBoatIcon,
            label: 'resources.products.aside.arrival'
        },
    };

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <Box ml={{md: 2}} mt={2}> {list?.length ?
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.products.aside.purchase_order_headline')}
                    </Typography>
                    <Stepper orientation="vertical" sx={{mt: 1}}>
                        {list?.map((purchaseOrder: any) => {
                            const type = types[purchaseOrder.type];
                            return (
                                <Step
                                    key={`${purchaseOrder.id}-po`}
                                    expanded
                                    active
                                    completed
                                >
                                    <StepLabel
                                        icon={
                                            <type.icon
                                                color="disabled"
                                                sx={{pl: 0.5, fontSize: '1.25rem'}}
                                            />
                                        }
                                        title={translate(type.label)}
                                    >
                                        {new Date(purchaseOrder.arrivalDate).toLocaleString(locale, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: 'numeric',
                                            minute: 'numeric',
                                        })}
                                    </StepLabel>
                                    <StepContent title={translate(type.label)}>
                                        <Typography variant="body2" color="textSecondary">
                                            {translate('resources.products.aside.ordered_items', {
                                                smart_count: purchaseOrder.orderedItems
                                            })}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {translate('resources.products.aside.remaining_items', {
                                                smart_count: purchaseOrder.remainingItems
                                            })}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {translate('resources.products.aside.available_items', {
                                                smart_count: purchaseOrder.availableItems
                                            })}
                                        </Typography>
                                    </StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>
                </CardContent>
            </Card> : null}
        </Box>
    );
};

export default Aside;
