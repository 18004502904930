import * as React from 'react';
import {FC, useCallback} from 'react';
import {redirect} from 'react-router-dom';
import {
    Edit,
    EditProps,
    TextInput,
    SelectInput,
    PasswordInput,
    useTranslate,
    required,
    email,
    SimpleForm,
    minLength,
    useUpdate,
    useRecordContext,
    useNotify,
    usePermissions,
    useGetIdentity,
    Toolbar,
    SaveButton, ToolbarProps, DeleteButton,
} from 'react-admin';
import {Box, Typography} from '@mui/material';
import MfaEditSection from './MfaEditSection';
import {UserEditType} from '../types';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {hasAccess} from 'ra-auth-acl';
import roles from "./roles";

const UserEdit: FC<EditProps> = props => {
    return (
        <Edit {...props} mutationMode="pessimistic">
            <UserEditForm/>
        </Edit>
    );
};

const validatePasswords = async (values: UserEditType) => {
    const errors = {};

    if (values?.password) {
        if (
            !values.passwordRepeat ||
            values.passwordRepeat !== values.password
        ) {
            (errors as UserEditType).passwordRepeat =
                'resources.user.validation.password_dont_match';
        }
    }

    return errors;
};

const UserEditForm = (props: any) => {
    const record = useRecordContext(props);
    const notify = useNotify();
    const translate = useTranslate();
    const [update] = useUpdate();
    const {permissions} = usePermissions();
    const {data} = useGetIdentity();

    useDefineAppLocation('settings.settings_user.edit', {record});

    const save = useCallback(
        async values => {
            try {
                await update(
                    `user`,
                    {id: record.id, data: values},
                    {returnPromise: true}
                );

                notify('resources.user.updated_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    return error.body.error.errors;
                }

                if (error?.body?.error?.message) {
                    notify(error.body.error.message, {
                        type: 'error',
                        autoHideDuration: 5000,
                    });
                }
            }
        },
        [update, notify, redirect]
    );
    let disabled = true;
    const accessType = hasAccess(permissions, 'user.edit');

    if (accessType === true) {
        disabled = false;
    } else if (accessType === 'own' && data?.id === record.id) {
        disabled = false;
    }

    const MyToolbar: FC<ToolbarProps> = props => (
        <Toolbar {...props}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                {disabled ? null : <SaveButton/>}
                {disabled ? null : <DeleteButton/>}
            </Box>
        </Toolbar>
    );

    return (
        <SimpleForm {...props} validate={validatePasswords} onSubmit={save} toolbar={<MyToolbar/>}>
            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.identity')}
            </Typography>
            <Box display={{xs: 'block', sm: 'flex'}} sx={{width: '100%'}}>
                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                    <TextInput
                        name="firstName"
                        source="firstName"
                        resource="user"
                        validate={requiredValidate}
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                    <TextInput
                        name="lastName"
                        source="lastName"
                        resource="user"
                        validate={requiredValidate}
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Box>

            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.contact')}
            </Typography>
            <Box display={{xs: 'block', sm: 'flex'}} sx={{width: '100%'}}>
                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                    <TextInput
                        name="email"
                        type="email"
                        source="email"
                        resource="user"
                        validate={[email(), required()]}
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                    <TextInput name="phone" source="phone" resource="user" fullWidth disabled={disabled}/>
                </Box>
            </Box>

            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.access_role')}
            </Typography>
            <SelectInput
                label={`resources.user.fields.accessRole`}
                name="accessRoleId"
                source="accessRoleId"
                choices={roles}
                disabled={disabled}
            />

            <Typography variant="h6" gutterBottom>
                {translate('resources.user.fieldGroups.change_password')}
            </Typography>
            <PasswordInput
                label={`resources.user.fields.oldPassword`}
                name="oldPassword"
                source="oldPassword"
                resource="user"
                fullWidth
                disabled={disabled}
            />
            <Box display={{xs: 'block', sm: 'flex'}} sx={{width: '100%'}}>
                <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                    <PasswordInput
                        label={`resources.user.fields.newPassword`}
                        name="password"
                        source="password"
                        resource="user"
                        validate={[minLength(6)]}
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
                <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                    <PasswordInput
                        label={`resources.user.fields.repeatNewPassword`}
                        name="passwordRepeat"
                        source="passwordRepeat"
                        resource="user"
                        fullWidth
                        disabled={disabled}
                    />
                </Box>
            </Box>
            <MfaEditSection/>
        </SimpleForm>
    );
};

const requiredValidate = [required()];

export default UserEdit;
