import * as React from 'react';
import {
    useTranslate,
    useRecordContext,
    Edit,
    SimpleForm,
    Toolbar,
    DateField
} from 'react-admin';
import {
    Typography,
    Card,
    CardContent,
    Box,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Alert,
    Tooltip,
    Button,
    List,
    Container
} from '@mui/material';
import {Dealer} from '../types';
import {AutoSave} from '@react-admin/ra-form-layout';
import {MarkdownInput} from "@react-admin/ra-markdown";
import {Info} from "@mui/icons-material";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {useState} from "react";

const Aside = () => {
    const record = useRecordContext<Dealer>();
    return (
        <Box width={{xs: "100%", md: 300, lg: 400}} sx={{minWidth: 300}}>
            {(record?.assortment && record.assortment.length > 0) ? <StatsComponent record={record}/> : null}
            {record && <InternalNoteComponent/>}
        </Box>
    );
};

const AssortmentElement = (props: { record: Dealer }) => {

    const assortment: string[] | undefined = props.record?.assortment;
    let assortmentToRender: string[] = [];

    if (!assortment) {
        assortmentToRender.push("N/A");
    }

    const stats = props.record?.stats;

    if (assortment && stats) {
        assortmentToRender = assortment.map((tag) => {
            return `${tag} (${stats[tag]['percentageOfAllProducts']}%)`;
        });
    }

    if (assortmentToRender.length === 0) {
        assortmentToRender.push("N/A");
    }

    return <>{assortmentToRender.join(', ')}</>;
}
const ProductTypesElement = (props: { record: Dealer }) => {

    const stats = props.record?.stats;
    let typesToRender: string[] = [];

    if (stats) {
        const types = Object.keys(stats);
        typesToRender = types.filter((tag) => {
            return ['Trailers', 'Accessories', 'Spare'].includes(tag) && stats[tag]['status'];
        });

        typesToRender = typesToRender.map((tag) => {
            return `${tag} (${stats[tag]['count']}/${stats[tag]['all']})`;
        });
    } else {
        typesToRender.push("N/A");
    }

    return <>{typesToRender.join(', ')}</>;
}

const InternalNoteComponent = () => {
    return (
        <Box ml={{md: 2}} mt={0}>
            <Edit sx={{'& .RaEdit-main': {marginTop: 0}}} mutationMode="pessimistic" title={undefined}>
                <SimpleForm
                    resetOptions={{keepDirtyValues: true}}
                    toolbar={<Toolbar><AutoSave debounce={1000}/></Toolbar>}
                >
                    <MarkdownInput source="internalNote" name="internalNote" label={`resources.dealers.aside.stats`}/>
                </SimpleForm>
            </Edit>
        </Box>
    );
};
const StatsComponent = (props: { record: Dealer }) => {
    const translate = useTranslate();

    return (
        <Box ml={{md: 2}} mb={{md: 2}} mt={0}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.dealers.aside.stats')}
                    </Typography>
                    <ListItem>
                        <ListItemIcon>
                            <Tooltip title={translate('resources.dealers.aside.assortment_info')}>
                                <Info/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                            primary={<AssortmentElement record={props.record}/>}
                            secondary={translate('resources.dealers.list.assortment')}
                        />
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>
                            <Tooltip title={translate('resources.dealers.aside.type_info')}>
                                <Info/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                            primary={<ProductTypesElement record={props.record}/>}
                            secondary={translate('resources.dealers.aside.productTypes')}
                        />
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>
                            <Info/>
                        </ListItemIcon>
                        <ListItemText
                            primary={props.record?.numberOfOrdersStats ? props.record.numberOfOrdersStats : 'N/A'}
                            secondary={translate('resources.dealers.aside.number_of_orders_stats')}
                        />
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>
                            <CalendarTodayIcon/>
                        </ListItemIcon>
                        <ListItemText
                            secondary={<ListOfOrdersDates record={props.record}/>}
                            primary={<p style={{
                                color: 'rgba(0, 0, 0, 0.6)',
                                margin: 0
                            }}>{translate('resources.dealers.aside.list_of_dates')}</p>}
                        />
                    </ListItem>
                    <Divider/>
                    <Alert severity="info" icon={<Info/>} sx={{marginTop: '20px'}}>
                        {translate(
                            'resources.dealers.aside.stats_info'
                        )}
                    </Alert>
                </CardContent>
            </Card>
        </Box>
    );
};

const ListOfOrdersDates = (props: { record: Dealer }) => {
    const translate = useTranslate();
    const dates: string[] = props.record?.ordersDates ?? [];
    const [showAll, setShowAll] = useState(false);
    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const displayedElements = showAll ? dates : dates.slice(0, 5);

    return <Container sx={{paddingLeft: '0 !important'}}>
        <List>
            {displayedElements.map((element, index) => (
                <ListItem key={index} sx={{padding: 0}}>
                    <ListItemText
                        sx={{margin: 0}}
                        primary={
                            element ? <DateField record={{date: element}}
                                                 source="date"
                                                 key="date"
                                                 label="resources.dealers.list.creationDate"
                                                 locales="de-DE" sx={{color: 'rgba(0, 0, 0, 0.87)'}}
                                                 options={{
                                                     weekday: undefined,
                                                     year: 'numeric',
                                                     month: '2-digit',
                                                     day: '2-digit',
                                                 }}
                                                 sortable={true}
                            /> : 'N/A'}
                    />
                </ListItem>
            ))}
        </List>

        {dates.length > 5 ? <Button variant="outlined" color="primary" size="small" onClick={handleShowMore}>
            {showAll ? translate('resources.dealers.aside.show_less') : translate('resources.dealers.aside.show_more')}
        </Button> : null}
    </Container>;
}


export default Aside;
