import * as React from 'react';
import {Chip} from '@mui/material';
import {useTranslate} from "react-admin";

export const FLAGS = {
    1: 'new',
    2: 'isComingSoon',
    3: 'discontinued',
};
export const FLAGS_TRANSLATION_KEYS = {
    1: 'resources.products.flags.new',
    2: 'resources.products.flags.isComingSoon',
    3: 'resources.products.flags.discontinued',
};

export const FLAGS_CHOICES = [
    {id: 1, name: 'resources.products.flags.new'},
    {id: 2, name: 'resources.products.flags.isComingSoon'},
    {id: 3, name: 'resources.products.flags.discontinued'},
];

const FLAGS_COLOR = {
    1: 'success',
    2: 'info',
    3: 'warning',
};

const FlagField = ({flag}) => {
    const t = useTranslate();

    if (flag && FLAGS[flag]) {
        return (
            <Chip
                variant="outlined"
                size="small"
                key={`flag-${flag}`}
                label={t(FLAGS_TRANSLATION_KEYS[flag])}
                color={FLAGS_COLOR[flag] ?? 'warning'}
            />
        );
    }

    return null;
};

export default FlagField;
