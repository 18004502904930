import ReportList from './ReportList';
import ReportShow from "./ReportShow";
import ReportCreate from "./ReportCreate";
import AssessmentIcon from '@mui/icons-material/Assessment';

const resource = {
    list: ReportList,
    show: ReportShow,
    create: ReportCreate,
    icon: AssessmentIcon,
};

export default resource;
