import * as React from 'react';
import {Card, CardHeader, CardContent, Select, MenuItem} from '@mui/material';
import {useLocaleState, useTranslate} from 'react-admin';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar
} from 'recharts';
import {formatPrice} from "../formatUtils";
import {useState} from "react";
import {SelectChangeEvent} from "@mui/material/Select";
import {buildHexColor} from "../utils/nameToHexColor";

const MonthlyRevenueChart = (props: { stats?: any }) => {
    const {stats} = props;
    const translate = useTranslate();
    const [locale] = useLocaleState();

    const {data} = stats;

    const marketplaces = data.marketplaces;

    const marketplaceNames = Object.keys(marketplaces);
    const defaultMarketplaceName = marketplaceNames[0];
    const defaultMarketplaceData = marketplaces[defaultMarketplaceName];

    let maxRevenue = Math.max.apply(Math, defaultMarketplaceData.map(function (o) {
        return o.revenue;
    }))
    let threshold = Math.ceil(maxRevenue / 25000) * 25000;

    const [marketplace, setMarketplace] = useState<string>(defaultMarketplaceName);
    const [dataCollection, setData] = useState(defaultMarketplaceData);
    const [domain, setDomain] = useState<number[]>([0, threshold]);

    const all = translate('resources.reports.quickViewReports.monthly.all');

    marketplaceNames.push(all);

    const handleMarketplaceChange = (event: SelectChangeEvent) => {
        let marketplaceName = event.target.value as string;
        let isAll = marketplaceName === all
        setMarketplace(marketplaceName);

        if (isAll) {
            let marketplaceData = data.all.map(function (item: any) {

                const keys = Object.keys(item).filter(key => marketplaceNames.includes(key) || key === 'firstDay');
                keys.push('month');

                return keys.reduce((acc, key) => {
                    acc[key] = item[key];
                    return acc;
                }, {});
            })

            let maxRevenue = 0;
            marketplaceData.forEach(obj => {
                Object.values(obj).forEach(value => {

                    const numericValue = parseFloat(value as string);
                    if (numericValue > maxRevenue) {
                        maxRevenue = numericValue;
                    }
                });
            });

            setMarketplace(marketplaceName);
            setData(marketplaceData);
            setDomain([0, Math.ceil(maxRevenue / 25000) * 25000]);

            return;
        }

        let marketplaceData = marketplaces[marketplaceName];

        let maxRevenue = Math.max.apply(Math, marketplaceData.map(function (o) {
            return o.revenue;
        }))
        let threshold = Math.ceil(maxRevenue / 25000) * 25000;

        setData(marketplaceData);
        setDomain([0, threshold]);
    };

    return (
        <Card>
            <CardHeader
                title={marketplace}
                subheader={translate('resources.reports.quickViewReports.monthly.title')}
                action={
                    <Select
                        value={marketplace}
                        onChange={handleMarketplaceChange} size={"small"}
                    >
                        {marketplaceNames.map((name) => (
                            <MenuItem key={name} value={name}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                }
            />
            <CardContent>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={dataCollection} margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="firstDay" tickFormatter={(date) => {
                                return new Intl.DateTimeFormat(locale, {month: "long"}).format(new Date(date));
                            }}/>
                            <YAxis domain={domain} name="Revenue" unit="€" allowDataOverflow={true}/>
                            <Tooltip
                                cursor={{strokeDasharray: '3 3'}}
                                formatter={(value: any, name: any) => [formatPrice(value, 'EUR'), marketplace === all ? name : translate(`resources.reports.quickViewReports.weekly.revenue`)]}
                            />
                            <Legend formatter={(value) => {
                                return marketplace === all ? value : marketplace;
                            }}/>
                            {
                                marketplace === all ?
                                    marketplaceNames.map((marketplace: any) => {

                                        return marketplace === all ? null :
                                            <Bar dataKey={marketplace} fill={buildHexColor(marketplace)}/>;
                                    })
                                    : <Bar dataKey="revenue" fill={buildHexColor(marketplace)}/>
                            }
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default MonthlyRevenueChart;
