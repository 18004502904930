import * as React from 'react';
import {
    List,
    TextField,
    FunctionField,
    useTranslate,
    Datagrid, SelectInput, TextInput, BooleanField, usePermissions
} from 'react-admin';
import {Chip, Tooltip} from '@mui/material';
import {Report} from "../types";
import {FREQUENCY_DICT, STATUSES, STATUSES_DICT, TYPES, TYPES_DICT} from "./dictionaries";
import {Update as UpdateIcon, LooksOne as LooksOneIcon} from "@mui/icons-material";
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {Link} from '@mui/material';
import {hasAccess} from "ra-auth-acl";

const listFilters = [
    <SelectInput
        source="type"
        choices={TYPES_DICT}
        name="type"
        label={`resources.reports.reports.filters.type`}
    />,
    <SelectInput
        source="status"
        choices={STATUSES_DICT}
        name="status"
        label={`resources.reports.reports.filters.status`}
    />,
    <SelectInput
        source="frequency"
        choices={FREQUENCY_DICT}
        name="frequency"
        label={`resources.reports.reports.filters.frequency`}
    />,
    <TextInput key="name" source="name" name="name" label={`resources.reports.reports.filters.name`}/>
];

const ReportList = () => {
    const t = useTranslate();
    useDefineAppLocation('reports.reports_list');
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'report.edit');

    return (<List
        sort={{field: 'number', order: 'DESC'}}
        perPage={25}
        filters={listFilters}
        hasCreate={editable}
    >
        <Datagrid
            sx={{
                '& .column-frequency': {
                    md: {display: 'none'},
                    lg: {display: 'table-cell'},
                },
            }}
            isRowSelectable={record => record?.deletedAt === undefined}
            bulkActionButtons={false}
            rowClick="show"
        >
            <TextField
                source="id"
                key="id"
                label={`resources.reports.reports.list.id`}
                sortable={false}
            />
            <TextField
                source="name"
                key="name"
                label={`resources.reports.reports.list.name`}
            />
            <FunctionField
                source="type"
                key="type"
                label={`resources.reports.reports.list.type`}
                render={(report: Report) => t(TYPES[report.type]['name'])}
            />
            <FunctionField
                source="frequency"
                key="frequency"
                label={`resources.reports.reports.list.frequency`}
                render={(report: Report) => {
                    if (report.frequency === 'ONE_TIME') {
                        return (
                            <Tooltip
                                title={t('resources.reports.reports.frequency.one_time')}
                            >
                                <LooksOneIcon/>
                            </Tooltip>
                        );
                    }

                    return (
                        <Tooltip
                            title={t('resources.reports.reports.frequency.recurring')}
                        >
                            <UpdateIcon/>
                        </Tooltip>
                    );
                }}
            />
            <FunctionField
                source="status"
                key="status"
                label={`resources.reports.reports.list.status`}
                render={(report: Report) => {
                    const _status = STATUSES[report.status];

                    return (
                        <Chip
                            size="small"
                            label={t(_status.name)}
                            color={_status.color}
                        />
                    );
                }}
            />
            <BooleanField
                source="isPrivate"
                key="isPrivate"
                label={`resources.reports.reports.list.isPrivate`}
            />
            <FunctionField
                source="creator"
                key="creator"
                label={`resources.reports.reports.list.createdBy`}
                render={(report: Report) => {
                    const creator = report.creator;

                    return (creator.owner ? `${creator.name}` :
                        <Link
                            href={`mailto:${creator.email}`}
                            onClick={e => e.stopPropagation()}
                            variant="body2"
                        >
                            {creator.name}
                        </Link>);
                }}
                sortable={false}
            />
        </Datagrid>
    </List>);
};

export default ReportList;
