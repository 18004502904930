import React, {useState} from "react";
import {
    FileField,
    FileInput,
    ResourceContextProvider, SaveButton,
    SimpleForm, Toolbar,
    useNotify,
    useRecordContext, Confirm,
    useTranslate,
    useUpdate
} from "react-admin";
import SortableList, {SortableItem} from "react-easy-sort";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {Alert} from "@mui/material";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductSizeAndColorGallery = (props: { editable: boolean }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const record = useRecordContext();

    const [colorImage, setColorImage] = useState(record.colorImage ? record.colorImage : null);
    const [sizeImage, setSizeImage] = useState(record.sizeImage ? record.sizeImage : null);

    const [colorDialog, toggleColorDialog] = useState(false);
    const [sizeDialog, toggleSizeDialog] = useState(false);
    const [update, {isLoading}] = useUpdate();

    const handleRemoveConfirm = (field: string) => {
        update(
            `products`,
            // @ts-ignore
            {id: record.id, data: {[field]: null}},
            {returnPromise: true}
        ).then(() => {

            if (field === 'colorImage') {
                toggleColorDialog(false);
                setColorImage(null);
            } else {
                toggleSizeDialog(false);
                setSizeImage(null);
            }

            notify('ra.notification.deleted', {
                type: 'success',
                autoHideDuration: 5000,
                messageArgs: {smart_count: 1}
            });
        }).catch((resp) => {
            if (field === 'colorImage') {
                toggleColorDialog(false);
            } else {
                toggleSizeDialog(false);
            }
        });
    };

    return (
        <SortableList
            onSortEnd={() => {
            }}
            style={{
                display: "flex",
                flexWrap: "wrap",
                userSelect: "none"
            }}
            draggedItemClassName="dragged"
            allowDrag={false}
        >
            {colorImage ? (
                    <SortableItem key={colorImage.url}>
                        <div
                            style={{
                                position: "relative",
                                flexShrink: 0,
                                display: "flex",
                                margin: 8,
                                userSelect: "none",
                                zIndex: 999
                            }}
                        >
                            <img
                                style={{
                                    width: 'auto',
                                    height: 50,
                                    pointerEvents: "none",
                                    objectFit: "cover"
                                }}
                                alt="Colour image"
                                src={colorImage.url}
                            />
                            <ButtonGroup variant="outlined" color="error">
                                {
                                    props.editable ?
                                        <ResourceContextProvider value="products">
                                            <EditInDialogButton
                                                mutationMode="pessimistic"
                                                title={translate('resources.products.show.update_colour_image_title')}
                                                icon={<ChangeCircleIcon fontSize="small"/>}
                                                ButtonProps={{size: 'large', style: {height: '100%'}}}
                                                transform={(data) => {
                                                    return {colorImage: data.media};
                                                }}
                                                mutationOptions={{
                                                    onSettled: (obj) => {
                                                        setColorImage(obj?.colorImage);
                                                        notify('ra.notification.created', {
                                                            type: 'success',
                                                            autoHideDuration: 5000,
                                                            messageArgs: {smart_count: 1}
                                                        });
                                                    }
                                                }}
                                            >
                                                <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                                                    <FileInput
                                                        source="media"
                                                        name={`media`}
                                                        accept="image/svg+xml,image/jpeg,image/jpg,image/png"
                                                        maxSize={1000000}
                                                    >
                                                        <FileField source="src" title="title"/>
                                                    </FileInput>
                                                    <Alert severity="info">
                                                        {translate('resources.products.show.accepted_files_format_colors_size')}
                                                    </Alert>
                                                </SimpleForm>
                                            </EditInDialogButton>
                                        </ResourceContextProvider>
                                        : null
                                }
                                <>
                                    {
                                        props.editable ?
                                            <Button onClick={() => toggleColorDialog(true)} startIcon={<DeleteIcon/>}>
                                                {translate('ra.action.delete')}
                                            </Button>
                                            : null
                                    }
                                    <Confirm
                                        isOpen={colorDialog}
                                        loading={isLoading}
                                        title={` `}
                                        content={`ra.message.delete_content`}
                                        onConfirm={() => handleRemoveConfirm('colorImage')}
                                        onClose={() => toggleColorDialog(false)}
                                    />
                                </>
                            </ButtonGroup>
                        </div>
                    </SortableItem>
                ) :
                (
                    <SortableItem key={1234}>
                        <div
                            style={{
                                position: "relative",
                                flexShrink: 0,
                                display: "flex",
                                margin: 8,
                                cursor: "pointer",
                                userSelect: "none",
                                boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                backgroundColor: "#f5f5f5",
                                width: 200,
                                height: 50,
                            }}
                        >
                            {
                                props.editable ?
                                    <ResourceContextProvider value="products">
                                        <EditInDialogButton
                                            mutationMode="pessimistic"
                                            title={translate('resources.products.show.add_colour_image_title')}
                                            icon={<AddToPhotosIcon fontSize="small"/>}
                                            label={`ra.action.add`}
                                            ButtonProps={{size: 'large', style: {width: '100%', height: '100%'}}}
                                            transform={(data) => {
                                                return {colorImage: data.media};
                                            }}
                                            mutationOptions={{
                                                onSettled: (obj) => {
                                                    setColorImage(obj?.colorImage);
                                                    notify('ra.notification.created', {
                                                        type: 'success',
                                                        autoHideDuration: 5000,
                                                        messageArgs: {smart_count: 1}
                                                    });
                                                }
                                            }}
                                        >
                                            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                                                <FileInput
                                                    source="media"
                                                    name={`media`}
                                                    accept="image/svg+xml,image/jpeg,image/jpg,image/png"
                                                    maxSize={1000000}
                                                >
                                                    <FileField source="src" title="title"/>
                                                </FileInput>
                                                <Alert severity="info">
                                                    {translate('resources.products.show.accepted_files_format_colors_size')}
                                                </Alert>
                                            </SimpleForm>
                                        </EditInDialogButton>
                                    </ResourceContextProvider>
                                    : null
                            }
                        </div>
                    </SortableItem>
                )
            }
            {sizeImage ? (
                    <SortableItem key={sizeImage.url}>
                        <div
                            style={{
                                position: "relative",
                                flexShrink: 0,
                                display: "flex",
                                margin: 8,
                                userSelect: "none",
                                boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
                                zIndex: 999
                            }}
                        >
                            <img
                                style={{
                                    width: 'auto',
                                    height: 50,
                                    pointerEvents: "none",
                                    objectFit: "cover"
                                }}
                                alt="Size image"
                                src={sizeImage.url}
                            />
                            {
                                props.editable ?
                                    <ButtonGroup variant="outlined" color="error">
                                        <ResourceContextProvider value="products">
                                            <EditInDialogButton
                                                mutationMode="pessimistic"
                                                title={translate('resources.products.show.update_size_image_title')}
                                                icon={<ChangeCircleIcon fontSize="small"/>}
                                                ButtonProps={{size: 'large', style: {height: '100%'}}}
                                                transform={(data) => {
                                                    return {sizeImage: data.media};
                                                }}
                                                mutationOptions={{
                                                    onSettled: (obj) => {
                                                        setSizeImage(obj?.sizeImage);
                                                        notify('ra.notification.created', {
                                                            type: 'success',
                                                            autoHideDuration: 5000,
                                                            messageArgs: {smart_count: 1}
                                                        });
                                                    }
                                                }}
                                            >
                                                <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                                                    <FileInput
                                                        source="media"
                                                        name={`media`}
                                                        accept="image/svg+xml,image/jpeg,image/jpg,image/png"
                                                        maxSize={1000000}
                                                    >
                                                        <FileField source="src" title="title"/>
                                                    </FileInput>
                                                    <Alert severity="info">
                                                        {translate('resources.products.show.accepted_files_format_colors_size')}
                                                    </Alert>
                                                </SimpleForm>
                                            </EditInDialogButton>
                                        </ResourceContextProvider>
                                        <>
                                            <Button onClick={() => toggleSizeDialog(true)} startIcon={<DeleteIcon/>}>
                                                {translate('ra.action.delete')}
                                            </Button>
                                            <Confirm
                                                isOpen={sizeDialog}
                                                loading={isLoading}
                                                title={` `}
                                                content={`ra.message.delete_content`}
                                                onConfirm={() => handleRemoveConfirm('sizeImage')}
                                                onClose={() => toggleSizeDialog(false)}
                                            />
                                        </>
                                    </ButtonGroup>
                                    : null
                            }
                        </div>
                    </SortableItem>
                ) :
                (
                    props.editable ? <SortableItem key={987}>
                        <div
                            style={{
                                position: "relative",
                                flexShrink: 0,
                                display: "flex",
                                margin: 8,
                                cursor: "pointer",
                                userSelect: "none",
                                boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                backgroundColor: "#f5f5f5",
                                width: 200,
                                height: 50,
                            }}
                        >
                            <ResourceContextProvider value="products">
                                <EditInDialogButton
                                    mutationMode="pessimistic"
                                    title={translate('resources.products.show.add_size_image_title')}
                                    icon={<AddToPhotosIcon fontSize="small"/>}
                                    label={`ra.action.add`}
                                    ButtonProps={{size: 'large', style: {width: '100%', height: '100%'}}}
                                    transform={(data) => {
                                        return {sizeImage: data.media};
                                    }}
                                    mutationOptions={{
                                        onSettled: (obj) => {
                                            setSizeImage(obj?.sizeImage);
                                            notify('ra.notification.created', {
                                                type: 'success',
                                                autoHideDuration: 5000,
                                                messageArgs: {smart_count: 1}
                                            });
                                        }
                                    }}
                                >
                                    <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                                        <FileInput
                                            source="media"
                                            name={`media`}
                                            accept="image/svg+xml,image/jpeg,image/jpg,image/png"
                                            maxSize={1000000}
                                        >
                                            <FileField source="src" title="title"/>
                                        </FileInput>
                                        <Alert severity="info">
                                            {translate('resources.products.show.accepted_files_format_colors_size')}
                                        </Alert>
                                    </SimpleForm>
                                </EditInDialogButton>
                            </ResourceContextProvider>
                        </div>
                    </SortableItem> : null
                )
            }
        </SortableList>
    );
};

export default ProductSizeAndColorGallery;
