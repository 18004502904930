function stringToHash(str: string): number {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function hashToHexColor(hash: number): string {
    let color = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        value = Math.floor(value * 0.8);
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export const buildHexColor = (string: string): string => {
    const hash = stringToHash(string);
    return hashToHexColor(hash);
};
