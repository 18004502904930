import * as React from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Link, useTranslate, useGetOne, DateField } from 'react-admin';

import { Order, CouponDictionaryItem } from '../types';
import { TableCellRight } from './TableCellRight';

const OrderDiscount = (props: { order: Order }) => {
    const { order } = props;
    const discounts = order.orderDiscount;
    const translate = useTranslate();

    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate('resources.order.show.discount')}
            </Typography>
            <div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {translate('resources.order.show.coupon')}
                            </TableCell>
                            <TableCell>
                                {translate('resources.order.show.coupon_code')}
                            </TableCell>
                            <TableCellRight>
                                {translate(
                                    'resources.order.show.discount_amount'
                                )}
                            </TableCellRight>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {discounts.map(discount => (
                            <TableRow key={discount.id}>
                                <TableCell>
                                    <Link to={`/coupon/${discount?.id}`}>
                                        {discount?.couponCode}
                                    </Link>
                                </TableCell>
                                <TableCell>{discount.couponCode}</TableCell>

                                {discount.type === 'product' ? (
                                    <TableCell>{discount.value}</TableCell>
                                ) : discount.type === 'percentage' ?
                                    <TableCellRight>
                                        {parseFloat((discount.value / 100).toString()).toLocaleString(
                                            'de-DE',
                                            {
                                                style: 'percent',
                                            }
                                        )}
                                    </TableCellRight>
                                    : (
                                    <TableCellRight>
                                        {parseFloat(discount.amount).toLocaleString(
                                            'de-DE',
                                            {
                                                style: 'currency',
                                                currency: 'EUR',
                                                currencySign: 'accounting',
                                            }
                                        )}
                                    </TableCellRight>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <Box m={1}>&nbsp;</Box>
        </>
    );
};

export default OrderDiscount;
