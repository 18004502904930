import * as React from 'react';
import {Card, CardActions, CardHeader, List, MenuItem, Select, Typography} from '@mui/material';
import {useTranslate} from 'react-admin';
import {Product} from "./Product";
import {useState} from "react";
import {format} from "date-fns";
import {SelectChangeEvent} from "@mui/material/Select";

const BestSellingReport = (props) => {
    const {stats} = props;
    const translate = useTranslate();

    const lists = stats.data;

    const [reportType, setReportType] = useState<string>('week');
    const [reportTitle, setReportTitle] = useState<string>(translate('resources.reports.quickViewReports.bestSellers.currentWeek'));
    const [products, setProducts] = useState(lists.week.items);

    const handleTypeChange = (event: SelectChangeEvent) => {
        let type = event.target.value as string;

        setProducts(lists[type].items);
        setReportType(type);
        setReportTitle(translate(`resources.reports.quickViewReports.bestSellers.current${type.charAt(0).toUpperCase() + type.slice(1)}`));
    };

    return (
        <Card sx={{flex: 1}}>
            <CardHeader
                title={reportTitle}
                subheader={translate('resources.reports.quickViewReports.bestSellers.tip')}
                action={
                    <Select
                        value={reportType}
                        onChange={handleTypeChange}
                        size={"small"}
                    >
                        <MenuItem key={'year'} value={'year'}>
                            {translate('resources.reports.quickViewReports.bestSellers.year')}
                        </MenuItem>
                        <MenuItem key={'month'} value={'month'}>
                            {translate('resources.reports.quickViewReports.bestSellers.month')}
                        </MenuItem>
                        <MenuItem key={'week'} value={'week'}>
                            {translate('resources.reports.quickViewReports.bestSellers.week')}
                        </MenuItem>
                    </Select>
                }
            />
            <List dense={true}>
                {products.map(product => (
                    <Product key={product.id} product={product}/>
                ))}
            </List>
            <CardActions>
                <Typography variant="caption" color="textSecondary">
                    {translate('resources.reports.quickViewReports.lastUpdate', {
                        smart_count: 1,
                        date: format(new Date(stats.updatedAt), 'dd.MM.yyyy HH:mm')
                    })}
                </Typography>
            </CardActions>
        </Card>
    );
};

export default BestSellingReport;
