import * as React from 'react';
import {useCallback, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import {Button, Card, CardActions, CircularProgress} from '@mui/material';
import {
    Form,
    useCreate,
    useTranslate,
    useNotify,
    useAuthProvider
} from 'react-admin';

import Box from '@mui/material/Box';
import backgroundImage from './images/background.jpg';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const MultiFactorAuthentication = () => {
    const [loading] = useState(false);
    const translate = useTranslate();
    const auth = useAuthProvider();

    const notify = useNotify();
    const navigate = useNavigate();

    const [create] = useCreate();

    const refs: any = {
        1: useRef<HTMLInputElement>(null),
        2: useRef<HTMLInputElement>(null),
        3: useRef<HTMLInputElement>(null),
        4: useRef<HTMLInputElement>(null),
        5: useRef<HTMLInputElement>(null),
        6: useRef<HTMLInputElement>(null)
    };

    const save = useCallback(async () => {
        const code = Object.values(refs)
            .map((input: any) => input.current.value)
            .join('');

        if (code.length !== 6) {
            notify('resources.user.six_digits_validation', {
                type: 'warning',
                autoHideDuration: 5000,
            });

            return;
        }

        try {
            if (auth?.getIdentity === undefined) {
                throw new Error();
            }

            const user = await auth.getIdentity();

            await create(
                `user/${user?.id}/mfa`,
                {data: {code}},
                {returnPromise: true}
            );
            navigate(`/`);
        } catch (error: any) {
            notify(
                error?.body?.error?.message ??
                'resources.user.something_went_wrong',
                {
                    type: 'warning',
                    autoHideDuration: 5000,
                }
            );
        }
    }, [create]);

    const focusNext = (event: any) => {
        const input = event.target;
        const id = parseInt(input.id);

        if (id < 6 && ((event.keyCode > 48 && event.keyCode < 58) || event.keyCode === 39)) {
            refs[id + 1].current.focus();
        }

        if (event.keyCode === 37 && id > 1) {
            refs[id - 1].current.focus();
        }
    };

    const MfaNumberField = (props: { number: string }) => {
        const {number} = props;

        return (
            <input
                id={number}
                ref={refs[number]}
                type="text"
                placeholder={number}
                onKeyUp={focusNext}
                min={0}
                max={9}
                maxLength={1}
                style={{
                    border: 'none',
                    borderRadius: '50%',
                    padding: '5px',
                    width: '50px',
                    height: '50px',
                    textAlign: 'center',
                    outline: 'none',
                }}
            />
        );
    };

    return (
        <Form onSubmit={save}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    background: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <Card sx={{minWidth: 300, marginTop: '6em'}}>
                    <Box
                        sx={{
                            padding: '1em',
                            display: 'flex',
                            justifyContent: 'left',
                            backgroundColor: '#F7F8FA',
                            color: '#272c36',
                            fontWeight: 'bold',
                        }}
                    >
                        Two-step authentication
                    </Box>
                    <Box
                        sx={{
                            marginTop: '1em',
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#272c36',
                            padding: '1em',
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#272c36',
                            fontSize: '14px',
                        }}>
                            {translate('resources.user.type_code_below')}
                        </div>
                    </Box>
                    <Box
                        sx={{
                            padding: '1em 1em 2em 1em',
                            maxWidth: '600px',
                            textAlign: 'center',
                        }}
                    >
                        <ToggleButtonGroup
                            exclusive
                            fullWidth={true}
                            sx={{maxWidth: '450px'}}
                        >
                            <ToggleButton value="1" aria-label="left aligned">
                                <MfaNumberField number="1"/>
                            </ToggleButton>
                            <ToggleButton value="2" aria-label="centered">
                                <MfaNumberField number="2"/>
                            </ToggleButton>
                            <ToggleButton value="3" aria-label="right aligned">
                                <MfaNumberField number="3"/>
                            </ToggleButton>
                            <ToggleButton value="4" aria-label="justified">
                                <MfaNumberField number="4"/>
                            </ToggleButton>
                            <ToggleButton value="5" aria-label="justified">
                                <MfaNumberField number="5"/>
                            </ToggleButton>
                            <ToggleButton value="6" aria-label="justified">
                                <MfaNumberField number="6"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <CardActions
                        sx={{
                            padding: '1em',
                            backgroundColor: '#F7F8FA',
                            justifyContent: 'right',
                        }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2}/>
                            )}
                            {translate('resources.user.verify_code')}
                        </Button>
                    </CardActions>
                </Card>
            </Box>
        </Form>
    );
};

MultiFactorAuthentication.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

const MultiFactorAuthenticationWithTheme = (props: any) => {

    return (
        <MultiFactorAuthentication {...props} />
    );
};

export default MultiFactorAuthenticationWithTheme;
