import * as React from 'react';
import {
    Datagrid,
    DateField,
    List,
    useRecordContext,
    useRefresh,
    useTranslate,
    TextField,
    NumberField, SimpleShowLayout, UrlField
} from 'react-admin';
import {Report} from '../types';
import {FunctionField} from 'ra-ui-materialui';
import {Box, Typography, CircularProgress, Container} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {ShowInDialogButton} from "@react-admin/ra-form-layout";
import DownloadIcon from '@mui/icons-material/Download';
import {FREQUENCY} from "./dictionaries";

const OneTimeReportEmpty = () => {
    const t = useTranslate();
    const refresh = useRefresh();

    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <Container maxWidth="sm" style={{textAlign: 'center', marginTop: 50, marginBottom: 50}}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <CircularProgress/>
                <Typography variant="h6" style={{marginTop: '20px'}}>
                    {t('resources.reports.reports.files.empty_one_time')}
                </Typography>
            </Box>
        </Container>
    );
};

const RecurringReportEmpty = () => {
    const t = useTranslate();

    return (
        <Container maxWidth="sm" style={{textAlign: 'center', marginTop: 50, marginBottom: 50}}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h6" style={{marginTop: '20px'}}>
                    {t('resources.reports.reports.files.empty_recurring')}
                </Typography>
            </Box>
        </Container>
    );
};

const Counter = ({invalidationTime}: { invalidationTime: string }) => {
    const [remainingTime, setRemainingTime] = useState<number>(0);

    useEffect(() => {
        const targetTime = new Date(invalidationTime).getTime();
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();
            const timeLeft = targetTime - currentTime;
            setRemainingTime(timeLeft > 0 ? timeLeft : 0);
        }, 1000);

        return () => clearInterval(interval);
    }, [invalidationTime]);

    const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
    const seconds = Math.floor((remainingTime / 1000) % 60);

    return (
        <span>{`${minutes}m ${seconds}s`}</span>
    );
};

const DownloadButton = () => {
    return (
        <ShowInDialogButton
            maxWidth="md"
            icon={<DownloadIcon/>}
            label={`resources.reports.reports.files.download`}
            resource={`report-files/download`}
            title={' '}
        >
            <SimpleShowLayout>
                <UrlField source="url" label={false} onClick={() => {
                    let close = document.getElementsByClassName('MuiModal-backdrop');
                    // @ts-ignore
                    close[0].click();
                }} />
                <FunctionField
                    label={`resources.reports.reports.files.expiration`}
                    render={record => <Counter invalidationTime={record.validUntil}/>}
                />
            </SimpleShowLayout>
        </ShowInDialogButton>
    );
};


const ReportFilesList = () => {
    const record = useRecordContext<Report>();
    useDefineAppLocation('reports.reports_list.show');

    if (!record) {
        return null;
    }

    return (
        <List
            pagination={false}
            resource={`report-files`}
            filter={{report: record.id}}
            actions={false}
            perPage={1000}
            empty={record.frequency === FREQUENCY.ONE_TIME.id ? <OneTimeReportEmpty/> : <RecurringReportEmpty/>}
        >
            <Datagrid optimized bulkActionButtons={false}>
                <TextField
                    source="name"
                    sortable={false}
                    label={`resources.reports.reports.files.file_name`}
                />
                <NumberField
                    source="downloadsNumber"
                    sortable={false}
                    label={`resources.reports.reports.files.downloads_number`}
                />
                <DateField
                    source="createdAt"
                    label={`resources.reports.reports.files.created_at`}
                    locales="de-DE"
                    options={{
                        weekday: undefined,
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: 'numeric',
                        minute: 'numeric',
                    }}
                    sortable={false}
                    showTime={true}
                />
                <DownloadButton/>
            </Datagrid>
        </List>
    );
};

export default ReportFilesList;
