import React, {useState} from "react";
import {
    DeleteWithConfirmButton,
    FileField,
    FileInput,
    SimpleForm,
    useNotify,
    useRefresh,
    useTranslate,
    useUpdate
} from "react-admin";
import SortableList, {SortableItem, SortableKnob} from "react-easy-sort";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import {arrayMoveImmutable} from "array-move";
import {Alert} from "@mui/material";

const ProductGallery = ({rawImages, record, editable}) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();

    const [update] = useUpdate();
    const [items, setItems] = useState(rawImages.sort((a, b) => a.position - b.position));

    const onSortEnd = (oldIndex: number, newIndex: number) => {

        setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));

        update(
            `product-media`,
            // @ts-ignore
            {id: items[oldIndex].id, data: {position: newIndex}},
            {returnPromise: true}
        ).then(() => {
            notify('resources.products.show.image_position_updated', {
                type: 'success',
                autoHideDuration: 5000,
            });
        }).catch((resp) => {
            setItems((array) => arrayMoveImmutable(array, newIndex, oldIndex));
        });
    };

    return (
        <SortableList
            onSortEnd={onSortEnd}
            style={{
                display: "flex",
                flexWrap: "wrap",
                userSelect: "none"
            }}
            draggedItemClassName={editable ? "dragged" : ''}
        >
            {items.map(({id, position, title, type, url}) => (
                <SortableItem key={id}>
                    <div
                        style={{
                            position: "relative",
                            flexShrink: 0,
                            display: "flex",
                            margin: 8,
                            cursor: "zoom-in",
                            userSelect: "none",
                            boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
                            zIndex: 999
                        }}
                        onClick={(event) => {
                            window.open(url, '_blank', 'noopener,noreferrer');
                        }}
                    >
                        {
                            editable ?
                                <SortableKnob>
                                    <SwapVertIcon
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            borderRadius: "0",
                                            backgroundColor: "rgba(255, 255, 255, .9)",
                                            color: "#626262",
                                            cursor: "move",
                                        }}
                                    />
                                </SortableKnob>
                                : null
                        }
                        {
                            type === 'video' ? (
                                <OndemandVideoIcon
                                    fontSize="large"
                                    style={{
                                        backgroundColor: '#80B9BF',
                                        color: "#626262",
                                        height: 150,
                                        width: 150,
                                        // padding: 50
                                    }}
                                />
                            ) : <img
                                style={{
                                    width: 'auto',
                                    height: 150,
                                    pointerEvents: "none",
                                    objectFit: "cover"
                                }}
                                alt={title}
                                src={url}
                            />
                        }

                        {
                            editable ?
                                <DeleteWithConfirmButton
                                    style={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        borderRadius: "0",
                                        backgroundColor: "rgba(255, 255, 255, .8)",
                                    }}
                                    confirmColor="warning"
                                    resource={'product-media'}
                                    translateOptions={{name: title}}
                                    record={{id}}
                                    redirect={false}
                                    mutationOptions={{
                                        onSuccess: () => {
                                            const index = items.findIndex((item) => item.id === id);
                                            const newItems = [...items];
                                            newItems.splice(index, 1);
                                            setItems(newItems);
                                            notify('ra.notification.deleted', {
                                                type: 'success',
                                                autoHideDuration: 5000,
                                                messageArgs: {smart_count: 1}
                                            });

                                            refresh();
                                        }
                                    }}
                                />
                                : null
                        }
                    </div>
                </SortableItem>
            ))}
            {
                editable ?
                    <SortableItem key={1234}>
                        <div
                            style={{
                                position: "relative",
                                flexShrink: 0,
                                display: "flex",
                                margin: 8,
                                cursor: "pointer",
                                userSelect: "none",
                                boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
                                zIndex: 999,
                                backgroundColor: "#f5f5f5",
                                width: 150,
                                height: 150,
                            }}
                        >
                            <CreateInDialogButton
                                resource="product-media"
                                record={{product: `/products/${record.id}`}}
                                title={`Add new media file`}
                                icon={<AddToPhotosIcon fontSize="large"/>}
                                label={`ra.action.add`}
                                ButtonProps={{size: 'large', style: {width: '100%', height: '100%'}}}
                                mutationOptions={{
                                    onSuccess: (newOne) => {
                                        const newItems = [...items];
                                        newItems.push(newOne);
                                        setItems(newItems);

                                        notify('ra.notification.created', {
                                            type: 'success',
                                            autoHideDuration: 5000,
                                            messageArgs: {smart_count: 1}
                                        });
                                    }
                                }}
                            >
                                <SimpleForm>
                                    <FileInput
                                        source="media"
                                        name={`media`}
                                        accept="image/webp,image/jpeg,image/jpg,image/png,video/mp4"
                                        maxSize={15000000}
                                    >
                                        <FileField source="src" title="title"/>
                                    </FileInput>
                                    <Alert severity="info">
                                        {translate('resources.products.show.accepted_files_format')}
                                    </Alert>
                                </SimpleForm>
                            </CreateInDialogButton>
                        </div>
                    </SortableItem>
                    : null
            }
        </SortableList>
    );
};

export default ProductGallery;
