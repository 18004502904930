import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    SearchInput,
    SimpleForm,
    required,
    TranslatableInputs,
    TextInput,
    useRedirect,
    DeleteWithConfirmButton,
    useRecordContext,
    FunctionField,
    usePermissions
} from 'react-admin';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {CreateInDialogButton} from "@react-admin/ra-form-layout";
import {Attribute} from "../types";
import {Link} from "@mui/material";
import {hasAccess} from "ra-auth-acl";

const AttributeCreateButton = () => {
    const redirect = useRedirect();

    const onSuccess = (data: Attribute) => {
        redirect(`/attributes/${data.id}`);
    };

    return (
        <CreateInDialogButton mutationOptions={{onSuccess}}>
            <SimpleForm>
                <TranslatableInputs locales={['de', 'en', 'fr']} fullWidth sx={{marginTop: 0}}>
                    <TextInput source="name_translatable" label="resources.products.show.attribute_name"
                               validate={[required()]}/>
                    <TextInput source="description_translatable" label="resources.products.show.attribute_desc"/>
                </TranslatableInputs>
            </SimpleForm>
        </CreateInDialogButton>
    );
};


const DeleteButton = () => {
    const record: Attribute = useRecordContext();

    if (record.isEditable) {
        return <DeleteWithConfirmButton
            redirect={false}
            mutationMode="optimistic"
            confirmTitle=" "
        />;
    }

    return null;
};

const AttributeList = () => {
    useDefineAppLocation('settings.settings_attributes');
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'attribute.edit');

    return (
        <List
            filters={[<SearchInput key="q" source="q" alwaysOn name="q"/>]}
            actions={editable ? <AttributeCreateButton/> : false}
        >
            <Datagrid
                rowClick="edit"
                isRowSelectable={(record: Attribute) => editable && record.isEditable}
            >
                <TextField source="id"/>
                <TextField source="name" label={`resources.attributes.fields.name`}/>
                <TextField source="tip" label={`resources.attributes.fields.tip`}/>
                <FunctionField
                    source="defaultMarketplace.name"
                    label={`resources.attributes.fields.origin_marketplace`}
                    sortable={false}
                    render={(attr: Attribute) => {
                        return (attr?.defaultMarketplace ?
                            <Link href={`/#${attr.defaultMarketplace['@id']}`}>
                                {attr.defaultMarketplace.name}
                            </Link> : "n/a");
                    }}
                />
                <TextField source="valueType" label={`resources.attributes.fields.value_type`}/>
                {editable ? <DeleteButton/> : null}
            </Datagrid>
        </List>
    );
};

export default AttributeList;
