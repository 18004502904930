import * as React from 'react';
import {FC} from 'react';
import {
    Create,
    CreateProps,
    TextInput,
    useTranslate,
    required,
    SelectInput,
    AutocompleteArrayInput,
    FormDataConsumer,
    DateInput, minValue, maxValue, BooleanInput,
} from 'react-admin';
import {Box} from '@mui/material';
import {WizardForm} from '@react-admin/ra-form-layout';
import {useDefineAppLocation} from "@react-admin/ra-navigation";
import {MarkdownInput} from "@react-admin/ra-markdown";
import {
    FREQUENCY_DICT,
    FREQUENCY,
    TYPES_DICT,
    FREQUENCY_VALUES,
    FREQUENCY_VALUES_DICT,
    TIME_RANGES, TYPES, REVENUE_TYPES_DICT, REFUNDS_TYPES_DICT
} from "./dictionaries";
import {useMarketplaces} from "../utils/useMarketplaces";

const ReportCreate: FC<CreateProps> = props => {
    useDefineAppLocation('reports.reports_list.create');

    const translate = useTranslate();
    const {marketplaces} = useMarketplaces();

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getDate()).padStart(2, '0');

    return (
        <Create {...props}>
            <WizardForm>
                <WizardForm.Step label={`resources.reports.reports.create.first_step_name`}>
                    <Box
                        display={{xs: 'block', sm: 'flex'}}
                        style={{width: '100%'}}
                    >
                        <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                            <TextInput
                                source="name"
                                name="name"
                                fullWidth
                                validate={required()}
                                label={`resources.reports.reports.create.name`}
                            />
                        </Box>
                        <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                            <BooleanInput
                                source="isPrivate"
                                name="isPrivate"
                                fullWidth
                                label={`resources.reports.reports.filters.isPrivate`}
                                helperText={`resources.reports.reports.filters.isPrivateHelper`}
                                defaultValue={true}
                            />
                        </Box>
                    </Box>
                    <Box
                        display={{xs: 'block', sm: 'flex'}}
                        style={{width: '100%'}}
                    >
                        <Box flex={2} mr={{xs: 0, sm: '0.5em'}}>
                            <SelectInput
                                source="type"
                                choices={TYPES_DICT}
                                name="type"
                                fullWidth
                                validate={required()}
                                label={`resources.reports.reports.filters.type`}
                            />
                        </Box>
                        <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                            <FormDataConsumer>
                                {({formData}) => {
                                    switch (formData.type) {
                                        case TYPES.REVENUE.id:
                                            return (
                                                <>
                                                    <SelectInput
                                                        source="groupBy"
                                                        choices={REVENUE_TYPES_DICT}
                                                        name="groupBy"
                                                        fullWidth
                                                        validate={required()}
                                                        label={`resources.reports.reports.filters.groupBy`}
                                                        helperText={`resources.reports.reports.filters.groupByHelper`}
                                                    />
                                                    <BooleanInput
                                                        source="withRefunds"
                                                        name="withRefunds"
                                                        fullWidth
                                                        label={`resources.reports.reports.filters.withRefundedOrders`}
                                                        helperText={`resources.reports.reports.filters.withRefundedOrdersHelper`}
                                                        defaultValue={true}
                                                    />
                                                    <BooleanInput
                                                        source="withShippingCost"
                                                        name="withShippingCost"
                                                        fullWidth
                                                        label={`resources.reports.reports.filters.withShippingCost`}
                                                        helperText={`resources.reports.reports.filters.withShippingCostHelper`}
                                                        defaultValue={true}
                                                    />
                                                </>
                                            );
                                        case TYPES.COUPONS.id:
                                            return (
                                                <BooleanInput
                                                    source="withShippingCost"
                                                    name="withShippingCost"
                                                    fullWidth
                                                    label={`resources.reports.reports.filters.withShippingCost`}
                                                    helperText={`resources.reports.reports.filters.withShippingCostHelper`}
                                                    defaultValue={true}
                                                />
                                            );
                                        case TYPES.BEST_SELLING_PRODUCTS.id:
                                            return (
                                                <BooleanInput
                                                    source="withRefunds"
                                                    name="withRefunds"
                                                    fullWidth
                                                    label={`resources.reports.reports.filters.withRefunded`}
                                                    helperText={`resources.reports.reports.filters.withRefundedHelper`}
                                                />
                                            );
                                        case TYPES.REFUNDED_PRODUCTS.id:
                                            return (
                                                <SelectInput
                                                    source="groupBy"
                                                    choices={REFUNDS_TYPES_DICT}
                                                    name="groupBy"
                                                    fullWidth
                                                    validate={required()}
                                                    label={`resources.reports.reports.filters.groupBy`}
                                                    helperText={`resources.reports.reports.filters.groupByHelper`}
                                                />
                                            );
                                        default:
                                            return null;
                                    }
                                }}
                            </FormDataConsumer>
                        </Box>
                    </Box>
                    <MarkdownInput
                        name="description"
                        source="description"
                        label={`resources.reports.reports.create.description`}
                        height="200px"
                    />
                </WizardForm.Step>
                <WizardForm.Step label={`resources.reports.reports.create.marketplaces`}>
                    <FormDataConsumer>
                        {({formData}) => {
                            switch (formData.type) {
                                case TYPES.COUPONS.id:
                                    return (
                                        <AutocompleteArrayInput
                                            name="marketplaces"
                                            source="marketplaces"
                                            choices={marketplaces.filter(marketplace => marketplace.sourceCode === 'ONLINE_SHOP')}
                                            validate={required()}
                                            label={`resources.reports.reports.create.marketplaces`}
                                            fullWidth
                                            blurOnSelect={false}
                                        />
                                    );
                                default:
                                    return (
                                        <AutocompleteArrayInput
                                            name="marketplaces"
                                            source="marketplaces"
                                            choices={marketplaces.filter(marketplace => marketplace.sourceCode !== 'SL')}
                                            validate={required()}
                                            label={`resources.reports.reports.create.marketplaces`}
                                            fullWidth
                                            disableCloseOnSelect={true}
                                            blurOnSelect={false}
                                        />
                                    );
                            }
                        }}
                    </FormDataConsumer>
                </WizardForm.Step>
                <WizardForm.Step label={`resources.reports.reports.filters.frequency`}>
                    <SelectInput
                        source="frequency"
                        choices={FREQUENCY_DICT}
                        validate={required()}
                        fullWidth
                        name="frequency"
                        label={`resources.reports.reports.filters.frequency`}
                    />
                    <FormDataConsumer>
                        {({formData}) => {
                            switch (formData.frequency) {
                                case FREQUENCY.ONE_TIME.id:
                                    return (
                                        <Box
                                            display={{xs: 'block', sm: 'flex'}}
                                            style={{width: '100%'}}
                                        >
                                            <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                                <DateInput
                                                    name="dateFrom"
                                                    source="dateFrom"
                                                    label={`resources.reports.reports.create.dateFrom`}
                                                    validate={[required()]}
                                                    fullWidth
                                                    helperText={translate('resources.reports.reports.create.one_time_tip')}
                                                />
                                            </Box>
                                            <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                                <DateInput
                                                    name="dateTo"
                                                    source="dateTo"
                                                    label={`resources.reports.reports.create.dateTo`}
                                                    validate={[required(), maxValue(`${year}-${month}-${day}`)]}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                    );
                                case FREQUENCY.RECURRING.id:
                                    return (
                                        <>
                                            <SelectInput
                                                source="frequencyValue"
                                                choices={FREQUENCY_VALUES_DICT}
                                                name="frequencyValue"
                                                validate={[required()]}
                                                label={`resources.reports.reports.frequency.report_frequency`}
                                                fullWidth
                                                helperText={
                                                    formData?.frequencyValue === FREQUENCY_VALUES.DAILY.id ?
                                                        translate('resources.reports.reports.create.recurring_tip_daily') :
                                                        formData?.frequencyValue === FREQUENCY_VALUES.WEEKLY.id ?
                                                            translate('resources.reports.reports.create.recurring_tip_weekly') :
                                                            formData?.frequencyValue === FREQUENCY_VALUES.MONTHLY.id ?
                                                                translate('resources.reports.reports.create.recurring_tip_monthly') :
                                                                ''
                                                }

                                            />
                                            <SelectInput
                                                source="timeRange"
                                                choices={TIME_RANGES}
                                                name="timeRange"
                                                validate={[required()]}
                                                label={`resources.reports.reports.frequency.time_range`}
                                                fullWidth
                                                helperText={translate('resources.reports.reports.create.daily_time_tip')}
                                            />
                                            <DateInput
                                                name="endDate"
                                                source="endDate"
                                                label={`resources.reports.reports.create.end_date`}
                                                validate={[required(), minValue(`${year}-${month}-${day}`)]}
                                                fullWidth
                                                helperText={translate('resources.reports.reports.create.end_date_tip')}
                                            />
                                        </>
                                    );
                                default:
                                    return null;
                            }
                        }}
                    </FormDataConsumer>
                </WizardForm.Step>
            </WizardForm>
        </Create>
    );
};

export default ReportCreate;
