import * as React from 'react';
import {
    BooleanInput,
    required,
    Show, SimpleForm, TextInput, TopToolbar, usePermissions, useRecordContext
} from 'react-admin';
import ReportShowContainer from "./ReportShowContainer";
import {EditInDialogButton} from '@react-admin/ra-form-layout';
import {MarkdownInput} from "@react-admin/ra-markdown";
import {Report} from '../types';
import {hasAccess} from "ra-auth-acl";

const EditAction = () => {
    const record = useRecordContext<Report>();

    if (!record) {
        return null;
    }

    return (
        record.creator.owner ? <TopToolbar>
            <EditInDialogButton
                mutationMode="pessimistic"
                title={` `}
            >
                <SimpleForm>
                    <TextInput
                        source="name"
                        name="name"
                        fullWidth
                        validate={required()}
                        label={`resources.reports.reports.create.name`}
                    />
                    <BooleanInput
                        source="isPrivate"
                        name="isPrivate"
                        fullWidth
                        label={`resources.reports.reports.filters.isPrivate`}
                        helperText={`resources.reports.reports.filters.isPrivateHelper`}
                    />
                    <MarkdownInput
                        name="description"
                        source="description"
                        label={`resources.reports.reports.create.description`}
                        height="200px"
                    />
                </SimpleForm>
            </EditInDialogButton>
        </TopToolbar> : null
    );
};

const ReportShow = () => {
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'report.edit');

    return (
        <Show
            actions={editable ? <EditAction/> : false}
            sx={{
                '& .RaShow-card': {
                    border: 'none',
                    background: 'transparent',
                },
            }}
        >
            <ReportShowContainer/>
        </Show>
    );
};

export default ReportShow;
