import * as React from 'react';
import {DateField, Edit, FunctionField, Labeled, TextField, useRecordContext, useTranslate,} from 'react-admin';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';

import {Address, Order} from '../types';
import Basket from './Basket';
import Totals from './Totals';
import OrderDiscount from './OrderDiscount';
import {statuses} from './orderStatus';
import countryCodes, {CountryProperty} from 'country-codes-list';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const OrderEdit = () => (
    <Edit title={<OrderTitle/>} component="div" actions={false}>
        <OrderShow/>
    </Edit>
);

const OrderTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext<Order>();
    return record ? (
        <span>
            {translate('resources.order.show.title', {
                reference: record?.number ?? record.id,
            })}
        </span>
    ) : null;
};

const AddressSection = (props: { address: Address }) => {
    const {address} = props;

    if (!address) {
        return null;
    }
    let countryName: string = '';
    let country: { countryNameEn?: string; flag?: string } = {flag: ''};

    if (address?.countryCode) {
        country = countryCodes.findOne('countryCode' as CountryProperty, address.countryCode);
        countryName = country?.countryNameEn ?? '';
    }

    return (
        <div>
            <Typography>
                {address.firstName} {address.lastName}
            </Typography>
            <Typography>{address?.company}</Typography>
            <Typography>
                {address?.street}, {address?.houseNumber}
            </Typography>
            <Typography>
                {address?.zipCode}, {address?.city}
            </Typography>
            <Typography>
                {countryName} {country?.flag}
            </Typography>
            <Typography>{address?.phoneNumber}</Typography>
            <Typography>{address?.email}</Typography>
        </div>
    );
};

const DealerSection = (props: { address: Address }) => {
    const {address} = props;
    const translate = useTranslate();

    if (!address) {
        return null;
    }

    return (
        <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" gutterBottom>
                {translate('resources.order.show.dealer_address')}
            </Typography>
            <AddressSection address={address}/>
        </Grid>
    );
};
const ParcelLockerSection = (props: { address: any }) => {
    const {address} = props;

    if (!address) {
        return null;
    }

    let countryName: string = '';
    let country: { countryNameEn?: string; flag?: string } = {flag: ''};

    if (address?.country) {
        country = countryCodes.findOne(
            'countryCode' as CountryProperty,
            address.country.toUpperCase()
        );
        countryName = country?.countryNameEn ?? '';
    }
    return (
        <div>
            <Typography>{address.name}</Typography>
            <Typography>{address.streetAddress}</Typography>
            <Typography>
                {address?.zipCode}, {address?.addressLocality}
            </Typography>
            <Typography>
                {countryName} {country?.flag}
            </Typography>
            <Typography>
                <a
                    href={`https://www.google.com/maps/@${address?.geo?.replace(
                        ';',
                        ','
                    )},14z`}
                    target="_blank"
                    rel="noreferrer"
                >
                    Map
                </a>
            </Typography>
        </div>
    );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderShow = () => {
    const translate = useTranslate();
    const empty = translate(`resources.order.show.none`);
    const record = useRecordContext<Order>();
    useDefineAppLocation('sales.orders.show', {record});

    if (!record) {
        return null;
    }

    return (
        <Box maxWidth="50em">
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant="h6" gutterBottom>
                                {translate('resources.order.show.headline')}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="createdAt"
                                        label={`resources.order.initiated_at`}
                                    >
                                        <DateField
                                            source="createdAt"
                                            showTime={true}
                                            locales="de-DE"
                                            options={{
                                                dateStyle: 'medium',
                                                timeStyle: 'short',
                                            }}
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="placedAt"
                                        label={`resources.order.show.placed_at`}
                                    >
                                        <DateField
                                            source="placedAt"
                                            showTime={true}
                                            locales="de-DE"
                                            options={{
                                                dateStyle: 'medium',
                                                timeStyle: 'short',
                                            }}
                                        />
                                    </Labeled>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="id"
                                        label={`resources.order.cart_reference_id`}
                                    >
                                        <TextField source="id"/>
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled source="number" label={`resources.order.number`}>
                                        <TextField source="number"/>
                                    </Labeled>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled source="status">
                                        <FunctionField
                                            source="status"
                                            label="resources.order.status"
                                            render={(record: Order) =>
                                                translate(
                                                    statuses[record.status]
                                                        .translationKey
                                                )
                                            }
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="sentToSelectLineAt"
                                        label={`resources.order.show.sent_to_sl_at`}
                                    >
                                        <DateField
                                            source="sentToSelectLineAt"
                                            showTime={true}
                                            emptyText={empty}
                                            locales="de-DE"
                                            options={{
                                                dateStyle: 'medium',
                                                timeStyle: 'short',
                                            }}
                                        />
                                    </Labeled>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="selectLineCustomerId"
                                        label={`resources.order.show.sl_customer_id`}
                                    >
                                        <TextField
                                            source="selectLineCustomerId"
                                            emptyText={empty}
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="selectLineDocumentNumber"
                                        label={`resources.order.show.sl_order_number`}
                                    >
                                        <TextField
                                            source="selectLineDocumentNumber"
                                            emptyText={empty}
                                        />
                                    </Labeled>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="dealerId"
                                        label={`resources.order.show.dealer_id`}
                                    >
                                        <TextField
                                            source="dealerId"
                                            emptyText={empty}
                                        />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Labeled
                                        source="dhlPostNumber"
                                        label={`resources.order.show.dhl_post_number`}
                                    >
                                        <TextField
                                            source="dhlPostNumber"
                                            emptyText={empty}
                                        />
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Spacer/>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.order.show.billing_address'
                                )}
                            </Typography>
                            <AddressSection address={record.billingAddress}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.order.show.shipping_address'
                                )}
                            </Typography>
                            {record.parcelLocker ? (
                                <ParcelLockerSection
                                    address={record.parcelLocker}
                                />
                            ) : (
                                <AddressSection
                                    address={record.shippingAddress}
                                />
                            )}
                        </Grid>
                        {record.dealerAddress ? (
                            <DealerSection address={record.dealerAddress}/>
                        ) : null}
                    </Grid>
                    <Spacer/>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.order.show.items')}
                    </Typography>
                    <div>
                        <Basket/>
                    </div>
                    <Spacer/>
                    {record.orderDiscount ? (
                        <OrderDiscount order={record}/>
                    ) : null}
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.order.show.total')}
                    </Typography>
                    <div>
                        <Totals order={record}/>
                    </div>
                </CardContent>
            </Card>
        </Box>
    );
};

export default OrderEdit;
