export const TYPES = {
    'REVENUE': {
        id: 'REVENUE',
        name: 'resources.reports.reports.types.revenue',
    },
    'BEST_SELLING_PRODUCTS': {
        id: 'BEST_SELLING_PRODUCTS',
        name: 'resources.reports.reports.types.best_selling_products',
    },
    'REFUNDED_PRODUCTS': {
        id: 'REFUNDED_PRODUCTS',
        name: 'resources.reports.reports.types.refunded_products',
    },
    'COUPONS': {
        id: 'COUPONS',
        name: 'resources.reports.reports.types.coupons',
    }
};

export const REVENUE_TYPES_DICT = [
    {
        id: 'TOTAL',
        name: 'resources.reports.reports.types.total',
    },
    {
        id: 'TOTAL_BY_MARKETPLACE',
        name: 'resources.reports.reports.types.total_by_marketplace',
    },
    {
        id: 'TOTAL_BY_COUNTRY',
        name: 'resources.reports.reports.types.total_by_country',
    },
    {
        id: 'TOTAL_BY_DAY',
        name: 'resources.reports.reports.types.total_by_day',
    },
    {
        id: 'TOTAL_BY_MONTH',
        name: 'resources.reports.reports.types.total_by_month',
    },
    {
        id: 'TOTAL_BY_DAY_MARKETPLACE',
        name: 'resources.reports.reports.types.by_day_marketplace',
    },
    {
        id: 'TOTAL_BY_MONTH_MARKETPLACE',
        name: 'resources.reports.reports.types.by_month_marketplace',
    }
];
export const REFUNDS_TYPES_DICT = [
    {
        id: 'TOTAL',
        name: 'resources.reports.reports.types.total',
    },
    {
        id: 'TOTAL_BY_MARKETPLACE',
        name: 'resources.reports.reports.types.total_by_marketplace',
    }
];
export const TYPES_DICT = [
    TYPES['REVENUE'],
    TYPES['BEST_SELLING_PRODUCTS'],
    TYPES['REFUNDED_PRODUCTS'],
    TYPES['COUPONS']
];

export const FREQUENCY = {
    'ONE_TIME': {
        id: 'ONE_TIME',
        name: 'resources.reports.reports.frequency.one_time',
    },
    'RECURRING': {
        id: 'RECURRING',
        name: 'resources.reports.reports.frequency.recurring',
    }
};

export const FREQUENCY_DICT = [
    FREQUENCY['ONE_TIME'],
    FREQUENCY['RECURRING']
];

export const FREQUENCY_VALUES = {
    'DAILY': {
        id: 'DAILY',
        name: 'resources.reports.reports.frequency.daily',
    },
    'WEEKLY': {
        id: 'WEEKLY',
        name: 'resources.reports.reports.frequency.weekly',
    },
    'MONTHLY': {
        id: 'MONTHLY',
        name: 'resources.reports.reports.frequency.monthly',
    }
};

export const FREQUENCY_VALUES_DICT = [
    FREQUENCY_VALUES['DAILY'],
    FREQUENCY_VALUES['WEEKLY'],
    FREQUENCY_VALUES['MONTHLY']
];

export const TIME_RANGES = [
    {
        id: '12am-1am',
        name: '00:00 - 01:00',
    },
    {
        id: '1am-2am',
        name: '01:00 - 02:00',
    },
    {
        id: '2am-3am',
        name: '02:00 - 03:00',
    },
    {
        id: '3am-4am',
        name: '03:00 - 04:00',
    },
    {
        id: '4am-5am',
        name: '04:00 - 05:00',
    },
    {
        id: '5am-6am',
        name: '05:00 - 06:00',
    },
    {
        id: '6am-7am',
        name: '06:00 - 07:00',
    },
    {
        id: '7am-8am',
        name: '07:00 - 08:00',
    },
    {
        id: '8am-9am',
        name: '08:00 - 09:00',
    },
    {
        id: '9am-10am',
        name: '09:00 - 10:00',
    },
    {
        id: '10am-11am',
        name: '10:00 - 11:00',
    },
    {
        id: '11am-12pm',
        name: '11:00 - 12:00',
    },
    {
        id: '12pm-1pm',
        name: '12:00 - 13:00',
    },
    {
        id: '1pm-2pm',
        name: '13:00 - 14:00',
    },
    {
        id: '2pm-3pm',
        name: '14:00 - 15:00',
    },
    {
        id: '3pm-4pm',
        name: '15:00 - 16:00',
    },
    {
        id: '4pm-5pm',
        name: '16:00 - 17:00',
    },
    {
        id: '5pm-6pm',
        name: '17:00 - 18:00',
    },
    {
        id: '6pm-7pm',
        name: '18:00 - 19:00',
    },
    {
        id: '7pm-8pm',
        name: '19:00 - 20:00',
    },
    {
        id: '8pm-9pm',
        name: '20:00 - 21:00',
    },
    {
        id: '9pm-10pm',
        name: '21:00 - 22:00',
    },
    {
        id: '10pm-11pm',
        name: '22:00 - 23:00',
    },
    {
        id: '11pm-12am',
        name: '23:00 - 00:00',
    }
];

export const STATUSES = {
    'NEW': {
        id: 'NEW',
        name: 'resources.reports.reports.status.new',
        color: 'primary'
    },
    'DONE': {
        id: 'DONE',
        name: 'resources.reports.reports.status.done',
        color: 'success'
    },
    'RUNNING': {
        id: 'RUNNING',
        name: 'resources.reports.reports.status.running',
        color: 'secondary'
    }
};

export const STATUSES_DICT = [
    STATUSES['NEW'],
    STATUSES['RUNNING'],
    STATUSES['DONE'],
];

export const REQUIREMENTS = {
    'MARKETPLACES': {
        name: 'resources.reports.reports.create.marketplaces',
        type: 'json'
    },
    'DATE_FROM': {
        name: 'resources.reports.reports.create.dateFrom',
        type: 'date'
    },
    'DATE_TO': {
        name: 'resources.reports.reports.create.dateTo',
        type: 'date'
    },
    'TIME_RANGE': {
        name: 'resources.reports.reports.frequency.time_range',
        type: 'range'
    },
    'FREQUENCY_VALUE': {
        name: 'resources.reports.reports.filters.frequency',
        type: 'string',
        values: FREQUENCY_VALUES_DICT
    },
    'WITH_REFUNDS': {
        name: 'resources.reports.reports.create.isRefundsIncluded',
        type: 'boolean'
    },
    'WITH_SHIPPING_COST': {
        name: 'resources.reports.reports.filters.withShippingCost',
        type: 'boolean'
    },
    'GROUP_BY': {
        name: 'resources.reports.reports.create.groupBy',
        type: 'string',
        values: [...REVENUE_TYPES_DICT, ...REFUNDS_TYPES_DICT]
    },
    'END_DATE': {
        name: 'resources.reports.reports.create.end_date',
        type: 'date'
    }
};
