import {useEffect, useState} from 'react'
import {Marketplace} from "../types";
import dataProvider from "../dataProvider";

interface MarketplacesResult {
    marketplaces: Marketplace[];
    isLoading: boolean;
}

export const useMarketplaces = (): MarketplacesResult => {
    const [marketplaces, setMarketplaces] = useState<Marketplace[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        dataProvider.getList<Marketplace>(`marketplaces`, {
            filter: {},
            sort: {field: 'id', order: 'ASC'},
            pagination: {page: 1, perPage: 1000},
            meta: {}
        })
            .then(({data}) => {
                setMarketplaces(data);
                setIsLoading(false);
            })
            .catch(error => {
            });
    }, []);

    return {marketplaces, isLoading};
}
