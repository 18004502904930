import * as React from 'react';
import {
    ListItem,
    ListItemSecondaryAction,
    ListItemAvatar,
    ListItemText,
    Avatar,
    Box,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {useTranslate} from 'react-admin';
import {formatPrice} from "../formatUtils";

export const Product = (props: { product: any }) => {
    const {product} = props;
    const translate = useTranslate();

    return (
        <ListItem button component={Link} to={`/products/${product.id}`}>
            <ListItemAvatar>
                <Avatar src={product.image} sx={{bgcolor: 'background.paper'}} alt={product.name}/>
            </ListItemAvatar>
            <ListItemText
                primary={product.name}
                secondary={translate('resources.reports.quickViewReports.bestSellers.items', {
                    smart_count: product.items,
                    nb_items: product.items
                })}
            />
            <ListItemSecondaryAction>
                <Box
                    component="span"
                    sx={{
                        marginRight: '1em',
                        color: 'text.primary',
                    }}
                >
                    {formatPrice(product.revenue, 'EUR')}
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
