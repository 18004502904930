import * as React from 'react';
import {Box, Card, CardContent, Chip, Grid, Stack} from '@mui/material';
import {FunctionField, Labeled, TextField, useRecordContext, useTranslate} from 'react-admin';
import {Dealer, DealerAddress} from "../../types";
import {FUNCTIONS} from "../DealerEdit";

const LocationShow = () => {
    const translate = useTranslate();
    const record = useRecordContext<DealerAddress>();
    if (!record) return null;

    const query = encodeURIComponent(record.street) + ',' + encodeURIComponent(record.zipCode) + ' ' + encodeURIComponent(record.city);

    let src: string = `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}&q=${query}`

    if (record.lat && record.lon) {
        src = `https://www.google.com/maps/embed/v1/place?key=${import.meta.env.VITE_GOOGLE_MAP_API_KEY}&q=${query}&center=${record.lat},${record.lon}`
    }

    return (
        <Card sx={{width: "90%", margin: 'auto', border: 'none'}}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="selectLineId"
                            label={`resources.dealers.address.selectLineId`}
                        >
                            <TextField source="selectLineId" key="selectLineId"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="salutation"
                            label={`resources.dealers.address.salutation`}
                        >
                            <TextField source="salutation" key="salutation"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="firstName"
                            label={`resources.dealers.address.firstName`}
                        >
                            <TextField source="firstName" key="firstName"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="lastName"
                            label={`resources.dealers.address.lastName`}
                        >
                            <TextField source="lastName" key="lastName"/>
                        </Labeled>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="street"
                            label={`resources.dealers.address.street`}
                        >
                            <TextField source="street" key="street"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="zipCode"
                            label={`resources.dealers.address.zipCode`}
                        >
                            <TextField source="zipCode" key="zipCode"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="city"
                            label={`resources.dealers.address.city`}
                        >
                            <TextField source="city" key="city"/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="country"
                            label={`resources.dealers.address.country`}
                        >
                            <TextField source="country" key="country"/>
                        </Labeled>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="addressLine1"
                            label={`resources.dealers.address.addressLine1`}
                        >
                            <TextField source="addressLine1" key="addressLine1" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="addressLine2"
                            label={`resources.dealers.address.addressLine2`}
                        >
                            <TextField source="addressLine2" key="addressLine2" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Labeled
                            source="addressLine3"
                            label={`resources.dealers.address.addressLine3`}
                        >
                            <TextField source="addressLine3" key="addressLine3" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="email"
                            label={`resources.dealers.address.email`}
                        >
                            <TextField source="email" key="email" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="email2"
                            label={`resources.dealers.address.email2`}
                        >
                            <TextField source="email2" key="email2" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="website"
                            label={`resources.dealers.address.website`}
                        >
                            <TextField source="website" key="website" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="faxNumber"
                            label={`resources.dealers.address.faxNumber`}
                        >
                            <TextField source="faxNumber" key="faxNumber" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="phoneNumber1"
                            label={`resources.dealers.address.phoneNumber1`}
                        >
                            <TextField source="phoneNumber1" key="phoneNumber1" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="phoneNumber2"
                            label={`resources.dealers.address.phoneNumber2`}
                        >
                            <TextField source="phoneNumber2" key="phoneNumber2" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="phoneNumber3"
                            label={`resources.dealers.address.phoneNumber3`}
                        >
                            <TextField source="phoneNumber3" key="phoneNumber3" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="phoneNumber4"
                            label={`resources.dealers.address.phoneNumber4`}
                        >
                            <TextField source="phoneNumber4" key="phoneNumber4" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="erpFunction"
                            label={`resources.dealers.address.function`}
                        >
                            <TextField source="erpFunction" key="erpFunction" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="department"
                            label={`resources.dealers.address.department`}
                        >
                            <TextField source="department" key="department" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="functions"
                            label={`resources.dealers.address.functions`}
                        >
                            <FunctionField
                                source="functions"
                                emptyText={`n/a`}
                                label={`resources.dealers.address.functions`}
                                render={(dealer: Dealer) => (
                                    <Stack direction="row" gap={1} flexWrap="wrap">
                                        {dealer.functions.length ? dealer.functions.map((funct, key) => {
                                            const _function = FUNCTIONS[funct];
                                            return (
                                                <Chip
                                                    size="small"
                                                    key={`${funct}-${key}`}
                                                    label={translate(_function.name)}
                                                    color={_function.color}
                                                />
                                            );
                                        }) : 'n/a'}
                                    </Stack>)}
                                sortable={false}
                            />
                        </Labeled>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="lat"
                            label={`resources.dealers.address.latitude`}
                        >
                            <TextField source="lat" key="lat" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Labeled
                            source="lon"
                            label={`resources.dealers.address.longitude`}
                        >
                            <TextField source="lon" key="lon" emptyText={`n/a`}/>
                        </Labeled>
                    </Grid>
                </Grid>
                <iframe
                    width="100%"
                    height="300"
                    loading="lazy" style={{margin: 'auto'}}
                    allowFullScreen
                    referrerPolicy="no-referrer-when-downgrade"
                    src={src}>
                </iframe>
            </CardContent>
        </Card>
    );
};

export default LocationShow;
