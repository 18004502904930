import * as React from 'react';
import {createElement, useState} from "react";
import {useTranslate} from 'react-admin';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Box, Card, CardHeader, Tooltip, Typography} from "@mui/material";
import {format} from "date-fns";
import {formatPrice} from "../formatUtils";

interface DailyRevenueProps {
    name: string;
    updatedAt: string;
    money: string;
    quantity: number;
}

const DailyRevenue = (props: DailyRevenueProps) => {
    const {money, quantity, name, updatedAt} = props;
    const translate = useTranslate();

    const [type, setType] = useState('revenue');
    const [title, setTitle] = useState<string>(translate('resources.reports.quickViewReports.daily.revenue'));
    const [value, setValue] = useState<string | number>(formatPrice(money, 'EUR'));

    const handleTypeChange = () => {
        if (type === 'revenue') {
            setType('quantity');
            setTitle(translate('resources.reports.quickViewReports.daily.orders'));
            setValue(quantity < 1 ? '0' : quantity);

            return;
        }

        setType('revenue');
        setTitle(translate('resources.reports.quickViewReports.daily.revenue'));
        setValue(formatPrice(money, 'EUR'));
    };

    return (
        <Card
            sx={{
                cursor: 'help',
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
            }}
            onClick={handleTypeChange}
        >
            <CardHeader
                subheader={<Typography variant="caption" color="textPrimary">{name}</Typography>}
                sx={{padding: '2px', textAlign: 'center'}}
            />
            <Tooltip
                title={translate('resources.reports.quickViewReports.lastUpdate', {
                    smart_count: 1,
                    date: format(new Date(updatedAt), 'dd.MM.yyyy HH:mm')
                })}
            >
                <Box
                    sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .icon': {
                            color: 'secondary.main',
                        },
                        '&:before': {
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            display: 'block',
                            content: `''`,
                            height: '200%',
                            aspectRatio: '1',
                            transform: 'translate(-30%, -60%)',
                            borderRadius: '50%',
                            backgroundColor: 'secondary.main',
                            opacity: 0.15,
                        },
                    }}
                >
                    <Box width="3em" className="icon">
                        {createElement(type === 'revenue' ? EuroIcon : ShoppingCartIcon, {fontSize: 'large'})}
                    </Box>
                    <Box textAlign="right">
                        <Typography color="textSecondary">{title}</Typography>
                        <Typography variant="h5" component="h2">
                            {value || ' '}
                        </Typography>
                    </Box>
                </Box>
            </Tooltip>
        </Card>
    );
};

export default DailyRevenue;
