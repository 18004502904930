import * as React from "react";
import {
    number,
    NumberInput,
    required,
    SimpleForm,
    TextInput,
    Toolbar,
    useRecordContext,
    SaveButton, regex, useRefresh, BooleanInput
} from "react-admin";
import {Product, ProductMarketplaceAllocation} from "../types";
import {EditInDialogButton} from "@react-admin/ra-form-layout";
import {InputAdornment} from "@mui/material";

const DefaultMarketplaceAllocationUpdateForm = (props: { record: Product }) => {
    const allocation: ProductMarketplaceAllocation = useRecordContext();
    const refresh = useRefresh();

    return (
        <EditInDialogButton
            mutationMode="optimistic"
            title={`'${props.record.name}' allocated in '${allocation.marketplace.name}'`}
            transform={(data: ProductMarketplaceAllocation) => {
                data.promoNetPrice = data.promoNetPrice ? String(data.promoNetPrice) : null;
                return data;
            }}
            mutationOptions={{
                onSuccess: () => {
                    refresh();
                }
            }}
        >
            <SimpleForm toolbar={<Toolbar><SaveButton/></Toolbar>}>
                <TextInput
                    source="promoNetPrice"
                    name="promoNetPrice"
                    fullWidth={true}
                    validate={[number(), regex(/^\d{1,9}\.\d{2,5}$/, 'resources.products.show.price_error_format')]}
                    InputProps={{startAdornment: (<InputAdornment position="start">€</InputAdornment>)}}
                />
                <NumberInput
                    source="minInventory"
                    name="minInventory"
                    fullWidth={true}
                    min={1}
                    max={999}
                    validate={[required(), number()]}
                />
                <BooleanInput source="isActive" name="isActive" validate={[required()]} label='resources.products.show.is_active'/>
            </SimpleForm>
        </EditInDialogButton>
    );
};

export default DefaultMarketplaceAllocationUpdateForm;
