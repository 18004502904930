import * as React from 'react';
import {
    TextField,
    Labeled,
    useRecordContext,
    useTranslate,
    useLocaleState,
    Create,
    SimpleForm,
    useRefresh,
    useNotify,
    useCreate,
    NumberField, usePermissions,
} from 'react-admin';
import {useController} from 'react-hook-form';
import {
    Card,
    CardContent,
    Box,
    Grid,
    CardHeader,
    Divider,
} from '@mui/material';

import cronstrue from 'cronstrue';
import 'cronstrue/locales/de';
import {CronJob} from '../types';
import {DateTimeInput} from 'ra-ui-materialui';
import {useCallback} from 'react';
import maintenancePeriodValidator from './validation/maintenancePeriodValidator';
import StateChip from './StateChip';
import {hasAccess} from "ra-auth-acl";

const CronJobIdHiddenInput = (props: { cronJobId: number }) => {
    const cronJobIdInput = useController({
        name: 'cronJobId',
        defaultValue: props.cronJobId,
    });

    return <input {...cronJobIdInput} type="hidden"/>;
};

const Aside = () => {
    const record = useRecordContext<CronJob>();
    const translate = useTranslate();
    const [locale] = useLocaleState();
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'server.edit');

    const refresh = useRefresh();
    const notify = useNotify();
    const [create] = useCreate();

    const save = useCallback(
        async values => {
            try {
                await create(
                    `server/cron-job-schedules`,
                    {data: values},
                    {returnPromise: true}
                );

                refresh();
                notify('resources.server.cron.created_successfully', {
                    type: 'success',
                    autoHideDuration: 5000,
                });
            } catch (error: any) {
                const e = error?.body?.error;

                if (e?.errors) {
                    if (e.errors?.codes || e.errors?.newCodes) {
                        notify(e.errors?.codes ?? e.errors?.newCodes, {
                            type: 'error',
                            autoHideDuration: 5000,
                        });
                    } else {
                        return error.body.error.errors;
                    }
                }
            }
        },
        [create]
    );

    if (!record) {
        return null;
    }

    return (
        <Box ml={2} width={400} display={{xs: 'none', lg: 'block'}}>
            <Card>
                <CardHeader
                    title={translate('resources.server.cron.jobInfo')}
                    action={<StateChip sx={{mt: 1, mr: 1}}/>}
                />
                <Divider/>
                <CardContent>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Labeled
                                source="cronId"
                                label={`resources.server.cron.id`}
                            >
                                <TextField source="cronId"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Labeled
                                source="schedulesRemaining"
                                label={`resources.server.cron.schedules_remaining`}
                            >
                                <NumberField source="schedulesRemaining"/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Labeled
                                source="name"
                                label={`resources.server.cron.name`}
                            >
                                <TextField source="name"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Labeled
                                source="description"
                                label={`resources.server.cron.description`}
                            >
                                <TextField source="description"/>
                            </Labeled>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Labeled
                                source="time"
                                label={`resources.server.cron.time`}
                            >
                                <TextField source="time"/>
                            </Labeled>
                        </Grid>
                        <Grid item xs={6} display="flex" gap={1}>
                            <Labeled
                                source="description"
                                label={`resources.server.cron.time_description`}
                            >
                                <>
                                    {cronstrue.toString(record.time, {
                                        locale,
                                        use24HourTimeFormat: true,
                                        verbose: true,
                                    })}
                                </>
                            </Labeled>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {editable ?
                <Card sx={{mt: 2}}>
                    <CardHeader
                        title={translate('resources.server.cron.add_new_period')}
                    />
                    <Divider/>
                    <CardContent>
                        <Create
                            resource={`server/cron-job-schedules`}
                            title={` `}
                            redirect={false}
                            sx={{
                                '& .RaCreate-card': {
                                    border: 'none',
                                    background: 'transparent',
                                    margin: '-30px -20px -25px',
                                },
                            }}
                        >
                            <SimpleForm
                                onSubmit={save}
                                validate={maintenancePeriodValidator}
                            >
                                <DateTimeInput
                                    label="resources.server.cron.start"
                                    source="timeStart"
                                    defaultValue={new Date()}
                                    sx={{width: '100%'}}
                                />
                                <DateTimeInput
                                    label="resources.server.cron.end"
                                    source="timeEnd"
                                    sx={{width: '100%'}}
                                />
                                <CronJobIdHiddenInput
                                    cronJobId={record.id as number}
                                />
                            </SimpleForm>
                        </Create>
                    </CardContent>
                </Card> : null}
        </Box>
    );
};

export default Aside;
