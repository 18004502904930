import * as React from 'react';
import {FC} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    FilterProps,
    SearchInput,
    EmailField,
    usePermissions,
    BulkDeleteWithConfirmButton,
    BooleanField,
} from 'react-admin';

import {useMediaQuery, Theme} from '@mui/material';
import {hasAccess} from 'ra-auth-acl';

import MobileGrid from './MobileGrid';
import {ListWithPermissionsProps} from '../types';
import BulkForceMfaButton from './BulkForceMfaButton';
import {useDefineAppLocation} from "@react-admin/ra-navigation";

const ListFilters = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SearchInput source="q" name="q" alwaysOn/>
    </Filter>
);

const UsersList: FC<ListWithPermissionsProps> = props => {
    const {permissions} = usePermissions();

    const isXsmall = useMediaQuery((theme: Theme) => {
        return theme.breakpoints.down('xs');
    });
    const PostBulkActionButtons = () => (
        <>
            {hasAccess(permissions, 'usermfa.enabled') ? <BulkForceMfaButton/> : null}
            {hasAccess(permissions, 'user.create') ? <BulkDeleteWithConfirmButton mutationMode="undoable"/> : null}
        </>
    );
    useDefineAppLocation('settings.settings_user');

    const bulkAllowed = hasAccess(permissions, 'usermfa.enabled') || hasAccess(permissions, 'user.create');

    return (
        <List hasCreate={hasAccess(permissions, 'user.create')}
              {...props}
              filters={<ListFilters/>}
              sort={{field: 'lastName', order: 'DESC'}}
              perPage={25}
        >
            {isXsmall ? (
                <MobileGrid/>
            ) : (
                <Datagrid
                    optimized
                    rowClick={permissions?.user?.edit ? `edit` : `show`}
                    bulkActionButtons={bulkAllowed ? <PostBulkActionButtons/> : false}
                >
                    <TextField source="id"/>
                    <TextField source="firstName"/>
                    <TextField source="lastName"/>
                    <EmailField source="email"/>
                    <TextField source="phone"/>
                    <BooleanField source="isMfaEnabled" label="resources.user.fields.isMfaEnabled"/>
                    <TextField
                        source="accessRoleName"
                        label="resources.user.fields.roleName"
                        sortable={false}
                    />
                </Datagrid>
            )}
        </List>
    );
};

export default UsersList;
