import {
    BooleanInput,
    Datagrid,
    Edit,
    FormDataConsumer,
    SimpleForm,
    TextField,
    TextInput,
    List,
    BooleanField,
    EditButton,
    useTranslate,
    DeleteWithConfirmButton,
    useRefresh,
    SelectInput, usePermissions, ToolbarProps, Toolbar, SaveButton
} from 'react-admin';
import {Box, Typography} from "@mui/material";
import * as React from "react";
import {useDefineAppLocation} from "@react-admin/ra-navigation";

import CategoryCreateForm from "./CategoryCreateForm";
import {hasAccess} from "ra-auth-acl";
import {FC} from "react";

const MarketplaceEdit = () => {
    const t = useTranslate();
    const refresh = useRefresh();
    const {permissions} = usePermissions();
    const editable = hasAccess(permissions, 'marketplace.edit');

    const MyToolbar: FC<ToolbarProps> = props => (
        editable ? <Toolbar {...props}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 1,
                    m: 1,
                    width: '100%',
                }}
            >
                <SaveButton/>
            </Box>
        </Toolbar> : null
    );

    return (<Edit>
        <SimpleForm toolbar={<MyToolbar/>}>
            <FormDataConsumer>
                {({formData}) => {
                    let record = formData;
                    useDefineAppLocation('settings.marketplaces.edit', {record});

                    return <React.Fragment>
                        <Box
                            display={{xs: 'block', sm: 'flex'}}
                            sx={{width: '100%'}}
                        >
                            <Box flex={5} mr={{xs: 0, sm: '0.5em'}}>
                                <TextInput disabled={!editable} source="name" name="name" fullWidth
                                           label='resources.marketplace.edit.name'/>
                            </Box>
                            <Box flex={2} ml={{xs: 0, sm: '0.5em'}}>
                                <SelectInput
                                    disabled={!editable}
                                    name="proxy"
                                    source="proxy"
                                    fullWidth
                                    choices={[
                                        {id: 'Mirakl', name: 'Mirakl'},
                                        {id: 'Smartview', name: 'Smartview'},
                                        {id: 'SL Api', name: 'SL Api'},
                                    ]}
                                />
                            </Box>
                            <Box flex={3} ml={{xs: 0, sm: '0.5em'}}>
                                <TextInput
                                    disabled={!editable}
                                    source="countryCode"
                                    name="countryCode" sx={{marginRight: '20px'}}
                                    label='resources.marketplace.edit.country'
                                />
                            </Box>
                        </Box>
                        <Box
                            display={{xs: 'block', sm: 'flex'}}
                            sx={{width: '100%'}}
                        >
                            <Box flex={1} mr={{xs: 0, sm: '0.5em'}}>
                                <TextInput
                                    disabled={!editable}
                                    source="description"
                                    name="description" multiline={true} minRows={3}
                                    fullWidth
                                    label='resources.marketplace.edit.description'
                                />
                            </Box>
                            <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                                <BooleanInput source="isActive" name="isActive" disabled={!editable}
                                              label='resources.marketplace.edit.is_active'/>
                            </Box>
                        </Box>
                        <Typography variant="h6" marginTop="20px" marginBottom="10px">
                            {t('resources.marketplace.edit.category_list_title')}
                        </Typography>
                        <List
                            resource={`categories`}
                            filter={{marketplace: formData.id}}
                            pagination={false}
                            hasCreate={editable}
                            empty={false}
                            sx={{width: '100%'}}
                            actions={editable ? <CategoryCreateForm id={formData.id}/> : false}
                        >
                            <Datagrid bulkActionButtons={false}>
                                <TextField source="id" sortable={false}
                                           label={`resources.marketplace.edit.category_id`}/>
                                <TextField source="name" sortable={false}
                                           label={`resources.marketplace.edit.category_name`}/>
                                <TextField source="code" sortable={false}
                                           label={`resources.marketplace.edit.category_code`}/>
                                <BooleanField source="isDefault" sortable={false}
                                              label={`resources.marketplace.edit.category_default`}/>

                                {
                                    editable ? <EditButton/> : null
                                }
                                {
                                    editable ?
                                        <DeleteWithConfirmButton
                                            redirect={false}
                                            mutationOptions={{
                                                onSuccess: () => {
                                                    refresh();
                                                }
                                            }}
                                        />
                                        : null
                                }
                            </Datagrid>
                        </List>
                    </React.Fragment>;
                }}
            </FormDataConsumer>
        </SimpleForm>
    </Edit>);
};

export default MarketplaceEdit;
