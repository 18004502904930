import * as React from 'react';
import {
    List,
    TextField,
    FunctionField,
    useTranslate,
    TextInput,
    SelectInput,
    DatagridConfigurable,
    TopToolbar,
    SelectColumnsButton, ExportButton, useStore, downloadCSV,
} from 'react-admin';
import {useMediaQuery, Theme, Stack, Chip} from '@mui/material';

import LocationShow from './LocationShow';
import {useDefineAppLocation} from '@react-admin/ra-navigation';
import {Dealer, DealerAddress} from "../../types";
import {FUNCTIONS} from "../DealerEdit";
import countryCodes, {CountryProperty} from "country-codes-list";
import {countries} from "../DealersListFilters";
import {useState} from "react";
import jsonExport from 'jsonexport/dist';

const listFilters = [
    <SelectInput
        source="country"
        alwaysOn
        choices={countries.map((code) => {

            let countryName: string = code;

            const country = countryCodes.findOne('countryCode' as CountryProperty, code);

            if (country) {
                countryName = country.countryNameEn;
            }

            return {
                id: code,
                name: countryName
            };
        })}
        name="country"
    />,
    <TextInput key="city" source="city" alwaysOn name="city" label={`resources.dealers.address.city`}/>
];

const DealersAddressesListActions = () => {
    const [backdrop, handleBackdrop] = useState(false);
    const toggleBackdrop = () => {
        handleBackdrop(!backdrop);
    };

    return (
        <TopToolbar>
            <SelectColumnsButton preferenceKey="dealers.address.list"/>
            <ExportButton maxResults={10000} onClick={() => toggleBackdrop()}/>
        </TopToolbar>
    );
};

const LocationsList = () => {
    useDefineAppLocation('settings.dealers_locations');
    const t = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    let [availableColumns] = useStore('preferences.dealers.address.list.availableColumns');
    let [selectedColumns] = useStore('preferences.dealers.address.list.columns');

    const exporter = (addresses: DealerAddress[]) => {
        const addressesForExport = addresses.map(address => {
            if (selectedColumns !== undefined) {

                let addressToExport = {};

                for (let i = 0; i < selectedColumns.length; i++) {
                    const column = availableColumns.find((column) => column.index === selectedColumns[i]);
                    if (column) {
                        if (column.source === 'dealer.name') {
                            addressToExport[t(`resources.dealers.address.${column.source}`)] = address.dealer.name;
                        } else {
                            addressToExport[t(`resources.dealers.address.${column.source}`)] = address[column.source];
                        }
                    }
                }

                return addressToExport;
            }

            return {
                [t(`resources.dealers.address.selectLineId`)]: address.selectLineId,
                [t(`resources.dealers.address.dealer.name`)]: address.dealer.name,
                [t(`resources.dealers.address.lastName`)]: address.lastName,
                [t(`resources.dealers.address.street`)]: address.street,
                [t(`resources.dealers.address.city`)]: address.city,
                [t(`resources.dealers.address.zipCode`)]: address.zipCode,
                [t(`resources.dealers.address.country`)]: address.country,
                [t(`resources.dealers.address.functions`)]: address.functions.join(', ')
            };
        });
        jsonExport(addressesForExport, {}, (err, csv) => {
            downloadCSV(csv, 'dealers_locations');
        });
    };

    return (
        <List
            exporter={exporter}
            filters={listFilters}
            perPage={25}
            actions={<DealersAddressesListActions/>}
            sx={{marginTop: isSmall ? undefined : 1}}
        >
            <DatagridConfigurable
                omit={[
                    'salutation',
                    'firstName',
                    'addressLine1',
                    'addressLine2',
                    'addressLine3',
                    'faxNumber', 'phoneNumber1', 'phoneNumber2', 'phoneNumber3',
                    'phoneNumber4', 'email', 'email2', 'website',
                    'lat', 'lon', 'erpFunction', 'department',
                ]}
                preferenceKey="dealers.address.list"
                rowClick="expand"
                expand={<LocationShow/>}
                bulkActionButtons={false}
            >
                <TextField source="selectLineId" label={`resources.dealers.address.selectLineId`}/>
                <TextField source="salutation" label={`resources.dealers.address.salutation`}/>
                <TextField source="dealer.name" label={`resources.dealers.address.dealer.name`} sortable={false}/>
                <TextField source="firstName" label={`resources.dealers.address.firstName`}/>
                <TextField source="lastName" label={`resources.dealers.address.lastName`}/>
                <TextField source="street" label={`resources.dealers.address.street`}/>
                <TextField source="city" label={`resources.dealers.address.city`}/>
                <TextField source="zipCode" label={`resources.dealers.address.zipCode`}/>
                <FunctionField
                    source="country"
                    key="country"
                    label={`resources.dealers.address.country`}
                    render={(address: DealerAddress) => {
                        const country = countryCodes.findOne('countryCode' as CountryProperty, address.country);

                        if (country) {
                            return `${country.flag} ${country.countryNameEn}`;
                        }

                        return address.country;
                    }}
                />
                <TextField source="addressLine1" label={`resources.dealers.address.addressLine1`}/>
                <TextField source="addressLine2" label={`resources.dealers.address.addressLine2`}/>
                <TextField source="addressLine3" label={`resources.dealers.address.addressLine3`}/>
                <TextField source="faxNumber" label={`resources.dealers.address.faxNumber`}/>
                <TextField source="phoneNumber1" label={`resources.dealers.address.phoneNumber1`}/>
                <TextField source="phoneNumber2" label={`resources.dealers.address.phoneNumber2`}/>
                <TextField source="phoneNumber3" label={`resources.dealers.address.phoneNumber3`}/>
                <TextField source="phoneNumber4" label={`resources.dealers.address.phoneNumber4`}/>
                <TextField source="email" label={`resources.dealers.address.email`}/>
                <TextField source="email2" label={`resources.dealers.address.email2`}/>
                <TextField source="website" label={`resources.dealers.address.website`}/>
                <TextField source="lat" label={`resources.dealers.address.lat`}/>
                <TextField source="lon" label={`resources.dealers.address.lon`}/>
                <TextField source="erpFunction" label={`resources.dealers.address.erpFunction`}/>
                <TextField source="department" label={`resources.dealers.address.department`}/>
                <FunctionField
                    source="functions"
                    label={`resources.dealers.address.functions`}
                    render={(dealer: Dealer) => (
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            {dealer.functions.map((funct, key) => {
                                const _function = FUNCTIONS[funct];
                                return (
                                    <Chip
                                        size="small"
                                        key={`${funct}-${key}`}
                                        label={t(_function.name)}
                                        color={_function.color}
                                    />
                                );
                            })}
                        </Stack>)}
                    sortable={false}
                />
            </DatagridConfigurable>
        </List>
    );
};

export default LocationsList;
